import React, { useEffect, useState } from 'react'
import './payroll.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse } from "@material-ui/core";
import { formatCurrency } from '../../../services/constant';
import { Stack } from '@mui/system';
import { getEmployeeSalary } from '../../../api-services/employee-services';
import jwt from 'jwt-decode';
import { JwtModel } from '../../../model/token-model/token.model';


interface SalaryStructureDetailsProps {
    name: string,
    totalAmmount?: number,
    details?: SalaryStructureDetails[]
}
interface SalaryStructureDetails {
    name: string,
    abbreviation: string,
    ammount: number,
    currancyCode: string
}
interface SalaryStructure {
    name?: string,
    value?: string | number
}
const UserPayroll = () => {
    const [collapseStatus, setCollapseStatus] = useState<number>(-1)
    const [salaryStructureDetails, setSalaryStructureDetails] = useState<SalaryStructureDetailsProps[]>([])
    const [salaryStructure, setSalaryStructure] = useState<SalaryStructure[]>([])
    const token = localStorage.getItem("loginToken") || ''
    let user: JwtModel = jwt(token);

    useEffect(() => {
        getEmployeeSalary(user.sub).then((response: any) => {
            if (response?.data.success && response.data.payload.length > 0) {
                let salary1 = response.data.payload.slice(0, 2)
                let salary2 = response.data.payload.length > 2 ? response.data.payload.slice(2) : [];
                setSalaryStructureDetails(salary2)
                setSalaryStructure(salary1)
            }
        })
    }, [])
    const handleCollapse = (e: any, key: number) => {
        if (collapseStatus === key) {
            setCollapseStatus(-1)
        } else {
            setCollapseStatus(key)
        }
    }
    useEffect(() => {
        setCollapseStatus(0)
    }, [])


    return (
        <>
            <div className='main'>
                <div className='main-container'>
                    <Stack height='100%'>
                        <div className='heading'>
                            <h2>SALARY</h2>
                        </div>
                        <div className='payroll-sub-container'>
                            {
                                salaryStructure.map((item: any, index: number) => {
                                    return <div className='payroll-field-container' key={index}>
                                        <div>{item.name}</div>
                                        <div>{(typeof item.value !== 'string') ? formatCurrency(item.value, item.currencyCode) : item.value}</div>
                                    </div>
                                })

                            }

                            <div>
                                {
                                    salaryStructureDetails.map((element: any, index: number) => {
                                        return (

                                            <div style={{ maxHeight: "inherit" }} key={index}>
                                                <div onClick={(e) => handleCollapse(e, index)} className='payroll-field-sub-container' key={index}>
                                                    {element.name !== 'Net Salary' ? <div className='payroll-field-sub-arrow-container' key={index}>{collapseStatus === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div> : ''}
                                                    <div className='payroll-field-details-sub-container'>
                                                        <div className={element.name === 'Net Salary' ? 'payroll-amount-container2' : ''}>{element.name}{element.details?.length > 0 ? `(${element.details.length})` : ""}</div>
                                                        <div className={element.name === 'Net Salary' ? 'payroll-amount-container2' : 'payroll-amount-container'}>{formatCurrency(element.totalAmount, element.currencyCode)}</div>
                                                    </div>

                                                </div>
                                                <Collapse
                                                    in={collapseStatus === index}
                                                >
                                                    {
                                                        element.details?.map((ele: any, ind: number) => {
                                                            return (
                                                                <React.Fragment key={ind}>
                                                                    {

                                                                        <div className='payroll-details-container'>
                                                                            <div className='payroll-details-heading'>{ele.name}{`(${ele.abbreviation})`}</div>
                                                                            <div className="payroll-details-sub-container">
                                                                                <div className='payroll-amount-container1'>{ele.ammount !== undefined ? (`${formatCurrency(ele.ammount, ele.currancyCode)}${" "}x${" "}1`) : ""}</div>
                                                                                <div className='payroll-amount-container'>{ele.ammount !== undefined ? formatCurrency(ele.ammount, ele.currancyCode) : ""}</div>
                                                                            </div>
                                                                            <div className='payroll-sub-details-container'>
                                                                                {
                                                                                    ele.details?.length > 0 ? ele.details.map((item: any, i: number) => {
                                                                                        return (

                                                                                            <div key={i}>
                                                                                                <div style={{ fontSize: "12px" }}>
                                                                                                    {item.name}
                                                                                                </div>
                                                                                                <div>
                                                                                                    {formatCurrency(item.ammount, item.currancyCode)}
                                                                                                </div>
                                                                                            </div>

                                                                                        )
                                                                                    }) : ""
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }

                                                </Collapse>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Stack>
                </div>
            </div >
        </>
    )
}

export default UserPayroll