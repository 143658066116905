import React, { useState, useEffect } from 'react';
import "./dashboard.css"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Box, Stack } from '@mui/system';
import { TextField } from '@mui/material';
import { breakIn, breakOut, isActiveClock, postClockIn, postClockOut } from '../../../api-services/employee-services';
import jwt from 'jwt-decode';
import { JwtModel } from '../../../model/token-model/token.model';
import { formateTimeZone } from '../../../services/constant';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { SnackbarService } from '../../../services/behaviorSubject/behaviorSubject';
import ButtonWrapper from '../../../widgets/button/button';

interface TimeData {
    clockIn: Date | null;
    clockOut: Date | null;
}

const UserDashboard = () => {
    const snackbarService = SnackbarService()
    let [isClockActive, setClockActiveStatus] = useState(false);
    let [loggedTime, setloggedTime] = useState<any>({
        agenda: null,
        clockIn: null,
        clockInDate: null,
        clockOut: null,
        employee_id: null,
        workUpdate: null,
        workingHours: null
    });

    const [breakoutStatus, setBreakoutStatus] = useState(false)
    const [openAgenda, setOpenAgenda] = React.useState(false);
    const [openWorkupdate, setOpenWorkupdate] = React.useState(false);
    const [error, setError] = useState('')
    const [agenda, setAgenda] = useState<any>(null)
    const [Workupdate, setWorkupdate] = useState("")
    const todayDate = new Date().toJSON().slice(0, 10);
    const [time, setTimer] = useState({ sec: 0, min: 0, h: 0 })
    const token = localStorage.getItem("loginToken") || ''
    console.log(token, "Ceck Token")
    let userConf: JwtModel;
    if (token !== '') {
        userConf = jwt(token);
    }
    const [isTabFocused, setIsTabFocused] = useState(true);

    useEffect(() => {
        const areAllBreakOutsNull = loggedTime.breaks?.every((breakEntry: { breakOut: null; }) => breakEntry.breakOut !== null);
        if (loggedTime.clockOut === null && areAllBreakOutsNull) {
            setBreakoutStatus(false)
        } else {
            setBreakoutStatus(true)
        }

    }, [loggedTime])


    let timer: any
    const updateCount = () => {
        if (isClockActive) {
            timer = !timer && setInterval(() => {
                setTimer(prevTime => {
                    let currentTime = { ...prevTime }
                    currentTime.sec = currentTime.sec >= 60 ? 1 : currentTime.sec < 60 ? currentTime.sec + 1 : 0
                    currentTime.min = currentTime.sec != 0 && currentTime.sec % 60 == 0 ? currentTime.min + 1 : currentTime.min != 0 && currentTime.min % 60 == 0 ? 0 : currentTime.min
                    currentTime.h = currentTime.min != 0 && currentTime.min % 60 == 0 ? currentTime.h + 1 : currentTime.h
                    return currentTime
                })
            }, 1000)
        }
    }

    const getCurrentTime = () => {
        return new Date().toISOString();
        // `${new Date().getHours().toString().padStart(2, '0')}:${new Date().getMinutes().toString().padStart(2, '0')}:${new Date().getSeconds().toString().padStart(2, '0')}`
    }

    const getStatus = () => {
        isActiveClock().then((res: any) => {
            if (res?.data.success) {
                setClockActiveStatus(res.data.payload.body.activeStatus)
                setloggedTime(res.data.payload.body)
                if (res.data.payload.body) {
                    if (res.data.payload.body.clockIn) {
                        getTimeWithBreaks(res.data.payload.body)
                    }
                }
            }
        })
    };
    const handleVisibilityChange = () => {
        if (!document.hidden) {
            getStatus()
        }
        else clearInterval(timer);
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const formate = (data: any) => {
        const date = new Date(data)
        return date
    }
    const clockIN = () => {
        postClockIn(agenda, userConf?.sub).then((res: any) => {
            if (res?.data.success) {
                snackbarService.next({ data: { open: true, severity: 'success', message: "Clock In Successfully..." } })
                getStatus()
            }
        })
    }

    const clockOUT = () => {
        postClockOut(Workupdate, userConf?.sub).then((response: any) => {
            if (response.data.success) {
                snackbarService.next({ data: { open: true, severity: 'success', message: "Clock Out Successfully..." } })
                getStatus()
            }
        })
    }
    const clockAction = (opt: any) => {
        switch (opt) {
            case 'IN':
                if (!userConf.agendaRequired) {
                    clockIN()
                } else {
                    setOpenAgenda(true)
                }
                break;
            case 'OUT':
                if (!userConf.workUpdateRequired) {
                    clockOUT()
                } else {
                    setOpenWorkupdate(true)
                }
                break;
            case 'BREAK':
                isClockActive = !isClockActive
                if (isClockActive)
                    breakOut(userConf.sub).then((response: any) => {
                        if (response.data.success) {
                            getStatus()
                            setBreakoutStatus(false)
                        }
                    })

                else
                    breakIn(userConf.sub).then((response: any) => {
                        if (response.data.success) {
                            getStatus()
                            setBreakoutStatus(true)
                        }
                    })

                break;
        }
    }

    console.log(breakoutStatus, "Breakout Status")

    const getElapsedTime = (loggedTime: any, currentTime: any) => {
        const time1 = new Date(loggedTime);
        const time2 = new Date(currentTime);

        const timeDifference = new Date(time2.getTime() - time1.getTime());

        let timeInseconds = timeDifference.getUTCHours() * 60 * 60 + timeDifference.getUTCMinutes() * 60 + timeDifference.getUTCSeconds()
        return timeInseconds
    }

    const getTimeFromSeconds = (sec: number) => {
        var hours = Math.floor(sec / 3600);
        var minutes = Math.floor((sec % 3600) / 60);
        var seconds = sec % 60;
        setTimer({ sec: seconds, min: minutes, h: hours })
    }
    const getTimeWithBreaks = (res: any) => {
        let totalSeconds = getElapsedTime(res.clockIn, (res.clockOut && res.clockOut != null) ? res.clockOut : getCurrentTime())
        let elapsedTime = 0
        res.breaks.forEach((br: any) => {
            if (br.breakIn && br.breakOut && br.breakOut != null) {
                elapsedTime += getElapsedTime(br.breakIn, br.breakOut)
            }
            else if (br.breakIn && (!br.breakOut || br.breakOut == null)) {
                totalSeconds = getElapsedTime(res.clockIn, br.breakIn)
            }
        })
        getTimeFromSeconds(totalSeconds - elapsedTime)
    }
    useEffect(() => {
        updateCount()
        return () => clearInterval(timer)
    }, [isClockActive])

    useEffect(() => {
        getStatus()
    }, [])

    const ClockInWithAgenda = () => {
        if (agenda !== '' && agenda !== null) {
            setOpenAgenda(false);
            clockIN()
        } else {
            setError('*Field is required')
        }
    };

    const clockOutWithAgenda = () => {
        if (Workupdate !== '') {
            setOpenWorkupdate(false)
            clockOUT()
        } else {
            setError('*Field is required')
        }

    }

    const handleCloseAgenda = () => {
        setOpenAgenda(false);
    }



    const handleCloseWorkUpdate = () => {
        setOpenWorkupdate(false)
    }

    return (
        <div className='main' >
            <div className='main-container'>
                <Stack height='100%'>
                    <div className='heading' >
                        <h2>Dashboard</h2>
                    </div>
                    <div className='sub-container'>
                        <div className="time-tracker">
                            <h1>Attendance</h1>
                            <div className='time-count-container'>
                                <span>{(time.h >= 10) ? time.h : "0" + time.h}</span><span>&nbsp;:&nbsp;</span>
                                <span>{(time.min >= 10) ? time.min : "0" + time.min}</span><span>&nbsp;:&nbsp;</span>
                                <span>{(time.sec >= 10) ? time.sec : "0" + time.sec}</span>
                            </div>
                            <div className="status">
                                <>
                                    <div>Status: {loggedTime?.clockIn && loggedTime?.clockOut === null ? 'Clocked In' : 'Clocked Out'}</div>
                                </>
                            </div>
                            <div className="clock-in-out">
                                <>
                                    <div style={{ marginTop: '4px' }}>Clock In Time: {formateTimeZone(loggedTime?.clockIn)}</div>
                                    <div>Clock Out Time: {formateTimeZone(loggedTime?.clockOut)}</div>
                                </>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                {loggedTime && loggedTime.clockIn && !loggedTime.clockOut &&
                                    < >
                                        {<div className='button-class-top '>
                                            <ButtonWrapper title='Clock Out' className='clock-out-button button-width' variant='contained' backgroundColor='var(--dangerColor)' onClick={() => { clockAction('OUT') }} disable={breakoutStatus} />
                                        </div>
                                        }
                                        <Dialog
                                            open={openWorkupdate}
                                            onClose={handleCloseWorkUpdate}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                {"Please write your workupdate here!"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <Box>
                                                    <TextField fullWidth
                                                        id="standard-textarea"
                                                        label={`My Workupdate ${todayDate}`}
                                                        placeholder="Placeholder"
                                                        multiline
                                                        variant="standard"
                                                        onChange={(e) => {
                                                            setWorkupdate(e.target.value)
                                                        }} />

                                                </Box>
                                                {
                                                    Workupdate === '' ? <span className='error-field'>{error}</span> : ''
                                                }
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseWorkUpdate}>Disagree</Button>
                                                <Button onClick={clockOutWithAgenda} autoFocus>
                                                    Agree
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                }
                                {loggedTime && !loggedTime.clockIn && !loggedTime.clockOut && <div className="clocked-out">
                                    <button onClick={() => { clockAction('IN') }}>Clock In</button>
                                    <Dialog
                                        open={openAgenda}
                                        onClose={handleCloseAgenda}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"Please write your agenda here!"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <Box>

                                                <TextField fullWidth
                                                    id="standard-textarea"
                                                    label={`My Agenda ${todayDate}`}
                                                    placeholder="Placeholder"
                                                    multiline
                                                    variant="standard"
                                                    onChange={(e) => {
                                                        setAgenda(e.target.value)
                                                    }} />

                                            </Box>
                                            {
                                                agenda === '' ? <span className='error-field'>{error}</span> : ''
                                            }
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseAgenda}>Disagree</Button>
                                            <Button onClick={ClockInWithAgenda} autoFocus>
                                                Agree
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                                }
                                {loggedTime && loggedTime && loggedTime.clockIn && !loggedTime.clockOut
                                    && <div className='button-class-top'>
                                        <ButtonWrapper title={loggedTime.activeStatus ? 'BREAK' : 'RESUME'} className='break-button' variant='contained' onClick={() => clockAction('BREAK')} backgroundColor='var(--primaryColor)' />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Stack>
            </div >
        </div >
    );
};

export default UserDashboard;

