import React, { useState } from 'react'
import AttendanceDetails from '../user/attendance-details/attendance-details';
import './custom-details-cell-render.css'
import ViewWorkFromHomeReasonDetails from '../../componant/viewWorkFromHomeReasonDetails/viewWorkFromHomeReasonDetails';
import ButtonWrapper from '../../widgets/button/button';
import ModalWrapper from '../../widgets/modal/modal';

const CustomDetailsCellRender = (props: { data: any, flag?: boolean }) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = () => {

        setOpen(true)
    };
    const handleClose = () => setOpen(false);
    console.log(props.data, "Data")
    return (
        <>
            <div className='button-class'>
                <ButtonWrapper className={`view-button`} title={`view`} onClick={handleOpen} />
            </div>
            <ModalWrapper close={handleClose} open={open} children={props.flag ? <ViewWorkFromHomeReasonDetails data={props.data.data} onClose={handleClose} /> : <AttendanceDetails data={props.data.data} onClose={handleClose} />
            } />
        </>
    )
}

export default CustomDetailsCellRender