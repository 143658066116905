import React, { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import swal from 'sweetalert';
import { ButtonList } from 'sweetalert/typings/modules/options/buttons';
import { deleteHoliday, deleteSingleEmployee } from '../../api-services/employee-services';
import FetchApi from '../../fetch-api/fetch-api';
import { Box } from '@mui/system';
import { Modal, Typography } from '@mui/material';
import EditHoliday from '../../componant/editHoliday/editHoliday';
import { SnackbarService } from '../../services/behaviorSubject/behaviorSubject';
import { modalStyle } from '../../style/style';
import ButtonWrapper from '../../widgets/button/button';
import ModalWrapper from '../../widgets/modal/modal';




const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

interface CustomCellRendererProps {
    value: string;
    handleEditEmployees?: (param: any) => void;
    actionStatus?: boolean;

}
const CustomCellRender: React.FC<CustomCellRendererProps> = ({ value, handleEditEmployees, actionStatus }) => {
    const style = modalStyle()
    const snackbarService = SnackbarService()
    const fetchApi = FetchApi()
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    const handleDeleteEmployee = (para: any) => {
        if (!actionStatus) {
            deleteSingleEmployee(para.data.employee_id).then(function (response: any) {
                if (response?.data) {
                    fetchApi.fetchAllEmployee()
                    snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
                }
            })

        } else {
            deleteHoliday(para.data.id).then((response: any) => {
                if (response?.data.success) {
                    fetchApi.fetchHoliday()
                    snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
                }
            })
        }
    }

    const handleEdit = (para: any) => {
        if (!actionStatus) {
            if (handleEditEmployees !== undefined) {
                handleEditEmployees(para.data)
            }
        } else {
            handleModalOpen()
        }

    }
    return (
        <div style={{ display: "flex", gap: "5%" }} >
            <ButtonWrapper buttonType='delete' onClick={() => handleDeleteEmployee(value)} tooltipTitle='Delete' tooltipPlacement='left' element={!actionStatus ? 'employee' : 'holiday'} />
            {
                <>
                    <ButtonWrapper buttonType='edit' className='edit-employee-button' tooltipTitle="Edit" tooltipPlacement='right' onClick={() => handleEdit(value)} />
                    <ModalWrapper open={modalOpen} close={handleModalClose} children={<EditHoliday onClose={handleModalClose} data={value} />} />
                </>
            }
        </div >
    )
}

export default CustomCellRender;