import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';
import './leave-review.css';
import { useSelector } from 'react-redux';
import ApproveRejectCell from '../../../custom-cell-render/approve-reject-cell';
import { useParams } from 'react-router';
import { getAllLeaveOfEmployee } from '../../../../api-services/employee-services';
import jwt from 'jwt-decode';
import { JwtModel } from '../../../../model/token-model/token.model';
import { FcApproval } from "react-icons/fc";
import { AiFillCloseCircle, AiOutlineRedo } from "react-icons/ai";
import { Stack } from '@mui/system';


const UserLeaveReview = () => {
    const [rowData, setRowData] = useState([]);
    const state = useSelector((state: any) => state.allEmployee);
    const params: any = useParams();
    const token = localStorage.getItem("loginToken") || ''
    let user: JwtModel = jwt(token);
    useEffect(() => {

        getAllLeaveOfEmployee(user.sub).then((response: any) => {
            if (response?.data.success) {
                setRowData(response.data.payload)
            }
        })
    }, []);

    return (
        <div className='main'>
            <div className='main-container'>
                <Stack height='100%'>
                    <div className='heading3'>
                        <h2>Leave Review</h2>
                    </div>
                    <div className='leave-review-main-container' style={{ paddingRight: '10px' }}>
                        {rowData.length > 0 ? (
                            rowData.map((el: any, i) => {
                                const parts = el.attachment.split('_');
                                const filename = parts[parts.length - 1].trim();
                                return <div key={i} >
                                    <Card className='card-container'>
                                        <CardContent className='card-content-container'>
                                            <div className="card-body pt-0" style={{ width: '100%' }}>
                                                <div
                                                    className="leave-review-data-container table table-bordered"
                                                >

                                                    <div className='leave-review-left-data-container'>
                                                        <div className='leave-review-sub-container'>
                                                            <div
                                                                className='leave-review-field-container'
                                                            >
                                                                Name
                                                            </div>
                                                            <div className='leave-review-field-container1'>:</div>
                                                            <div className='leave-review-field-container2'>
                                                                {el.name}
                                                            </div>
                                                        </div>

                                                        <div className='leave-review-sub-container'>
                                                            <div
                                                                className='leave-review-field-container'
                                                            >
                                                                Leave Type
                                                            </div>
                                                            <div className='leave-review-field-container1'>:</div>
                                                            <div className='leave-review-field-container2'>
                                                                {el.leaveType}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='leave-review-right-data-container'>
                                                        {
                                                            el.session === 'Full Day' ? <>
                                                                <div className='leave-review-sub-container'>
                                                                    <div className='leave-review-field-container3'
                                                                    >
                                                                        From Date
                                                                    </div>
                                                                    <div className='leave-review-field-container1'>:</div>
                                                                    <div className='leave-review-field-container4'>
                                                                        {el.fromDate
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='leave-review-sub-container'>
                                                                    <div className='leave-review-field-container3'
                                                                    >
                                                                        To Date
                                                                    </div>
                                                                    <div className='leave-review-field-container1'>:</div>
                                                                    <div className='leave-review-field-container4'>
                                                                        {el.toDate
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </> : <>
                                                                <div className='leave-review-sub-container'>
                                                                    <div className='leave-review-field-container3'>
                                                                        Working Date
                                                                    </div>
                                                                    <div className='leave-review-field-container1'>:</div>
                                                                    <div className='leave-review-field-container4'>
                                                                        {el.fromDate
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }

                                                        <div className='leave-review-sub-container'>
                                                            <div className='leave-review-field-container3'>
                                                                Session
                                                            </div>
                                                            <div className='leave-review-field-container1'>:</div>
                                                            <div className='leave-review-field-container4'>
                                                                {el.session}
                                                            </div>
                                                        </div>
                                                        {el.session === 'Half Day' ? <>
                                                            <div className='leave-review-sub-container'>
                                                                <div className='leave-review-field-container3'>
                                                                    Schedule
                                                                </div>
                                                                <div className='leave-review-field-container1'>:</div>
                                                                <div className='leave-review-field-container4'>
                                                                    {el.leavePeriod}
                                                                </div>
                                                            </div>
                                                        </> : ''
                                                        }
                                                        <div className='leave-review-sub-container'>
                                                            <div className='leave-review-field-container3'>
                                                                No of Days
                                                            </div>
                                                            <div className='leave-review-field-container1'>:</div>
                                                            <div className='leave-review-field-container4'>
                                                                {el.noOfDays}
                                                            </div>
                                                        </div>
                                                        <div className='leave-review-sub-container'>
                                                            <div
                                                                className='leave-review-field-container3'
                                                            >
                                                                Attachment
                                                            </div>
                                                            <div className='leave-review-field-container1'>:</div>
                                                            <div className='leave-review-field-container5'>
                                                                <div style={{ width: '100%' }}>
                                                                    {el.attachment !== 'NA' ? <div className='leave-review-field-container-file'>
                                                                        <div style={{ width: "100%" }}>{filename}</div>
                                                                    </div> : <div className='leave-review-field-container-no-file'>
                                                                        {el.attachment}
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='leave-review-sub-container'>
                                                            <div className='leave-review-field-container3'>
                                                                Reason for Leave
                                                            </div>
                                                            <div className='leave-review-field-container1'>:</div>
                                                            <div className='leave-review-field-container4'>
                                                                {el.reasonForLeave}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </CardContent>
                                        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '14px' }}>
                                            <div className={el.leaveStatus === 'approved' ? 'approve-status-container' : el.leaveStatus === 'rejected' ? 'reject-status-container' : 'pending-status-container'}>{el.leaveStatus === 'approved' ? <FcApproval /> : el.leaveStatus === 'rejected' ? <AiFillCloseCircle color='var(--dangerColor)' /> : <AiOutlineRedo color="var(--secondaryColor)" />}{el.leaveStatus}</div>
                                        </CardActions>
                                    </Card>
                                </div>
                            })
                        ) : (
                            <h3>No Data Found</h3>
                        )}
                    </div>
                </Stack>
            </div >
        </div >
    )
}

export default UserLeaveReview