import React, { useEffect, useState } from 'react'
import './leave.css'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LeaveDetails from '../../../componant/leave-details/leave-details';
import { Modal, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import LeaveApplication from '../../../componant/leave-application/leave-application';
import { Button } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { getEmployeeLeaveDetails } from '../../../api-services/employee-services';
import jwt from 'jwt-decode';
import { JwtModel } from '../../../model/token-model/token.model';
import { modalStyle } from '../../../style/style';
import { Grid } from '@material-ui/core';
import ButtonWrapper from '../../../widgets/button/button';
import ModalWrapper from '../../../widgets/modal/modal';




const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));



interface userLeaveDetails {
    leaveType: string,
    leaveBreaks: {
        earned: number,
        balance: number,
        carriedOver: number,
        entitled: number,
        taken: number,
        emergecy: number
    }
}
const UserLeave = () => {
    const style = modalStyle()
    const [userLeveDetails, setUserLeaveDetails] = useState<userLeaveDetails[]>([])
    const [showLeaveApplication, setShowLeaveApplication] = useState<boolean>(false)
    const [leaveType, setleaveType] = useState()
    const [openModal, setOpenModal] = useState<boolean>(false);
    const handleOpenModal = (item: any) => {
        setOpenModal(true);
        setleaveType(item)
    }
    const handleCloseModal = () => setOpenModal(false);
    const [openLeaveApplicationModal, setOpenLeaveApplicationModal] = useState<boolean>(false);
    const handleCloseLeaveApplicationModal = () => setOpenLeaveApplicationModal(false);
    const token = localStorage.getItem("loginToken") || ''
    let user: JwtModel = jwt(token);

    useEffect(() => {
        getEmployeeLeaveDetails(user.sub).then((response: any) => {
            if (response?.data.success)
                setUserLeaveDetails(response.data.payload)
        })
    }, [])

    const handleLeaveApplication = () => {
        setOpenLeaveApplicationModal(true);
    }


    return (
        <>
            <div className='main' >
                <div className='main-container'>
                    <Stack height='100%'>

                        <>
                            <div className="leave-heading-sub-container">
                                <div className='heading4'>
                                    <h2>Leave Details</h2>
                                    <ButtonWrapper buttonType='circle' className='circular-add-button' onClick={handleLeaveApplication} tooltipTitle='Apply Leave' tooltipPlacement='left' />
                                </div>
                            </div>
                            <div className='sub-body-container'>
                                <div className='leave-main-container'>
                                    <div className='leave-details-heading-container'>
                                        <div className='leave-type-head-container'>Type</div>
                                        <div className='leave-details-head-container'>Details</div>
                                    </div>
                                    <div className='user-leave-details-main-container'>
                                        <div className='user-leave-details-sub-container'>
                                            {
                                                userLeveDetails.map((item: any, index: number) => {
                                                    return (
                                                        <div className='leave-sub-container' key={index}>

                                                            <div className='leave-table-sub-container' >
                                                                <div className='leave-table-type-container'>
                                                                    <div className='leave-type-container'>
                                                                        <div>{item.leaveType}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='leave-table-details-container'>
                                                                    <div className='leave-table-details-sub-container'>
                                                                        <div className='leave-break-sub-container'>
                                                                            <div>
                                                                                <label>Balance</label>
                                                                                <div className='balance-container'>
                                                                                    <div className={item.leaveBreaks.balance !== 0 ? 'leave-balance-show-container' : 'leave-balance-show-container1'}>{`${item.leaveBreaks.balance} Days`}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <label>Carried Over</label>
                                                                                <div>{item.leaveBreaks.carriedOver}</div>
                                                                            </div>
                                                                            <div>
                                                                                <label>Taken</label>
                                                                                <div>{item.leaveBreaks.taken}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='leave-break-sub-container'>
                                                                            <div>
                                                                                <label>Earned</label>
                                                                                <div>{item.leaveBreaks.earned}</div>
                                                                            </div>
                                                                            <div>
                                                                                <label>Entitled</label>
                                                                                <div>{item.leaveBreaks.entitled}</div>
                                                                            </div>
                                                                            <div>
                                                                                <label>Emergency</label>
                                                                                <div>{item.leaveBreaks.emergency}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='leave-details-container'>
                                                                            <ButtonWrapper className='leave-details-button' title='Details' onClick={() => handleOpenModal(item)} titleStyle='button-text-container' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <ModalWrapper open={openLeaveApplicationModal} close={handleCloseLeaveApplicationModal} children={<LeaveApplication onClose={handleCloseLeaveApplicationModal} />} />
                                        <ModalWrapper open={openModal} close={handleCloseModal} children={<LeaveDetails items={leaveType} onClose={handleCloseModal} />} />
                                    </div>
                                </div>
                            </div>




                        </>
                    </Stack>
                </div>
            </div>
        </>
    )
}

export default UserLeave