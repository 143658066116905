import React, { useEffect, useRef, useState } from 'react'
import './salarySlip.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { detailsField } from '../../../../services/employeeFieldType';
import { downloadSalarySlip, downloadSelectedEmployeeSalarySlip, generateSalarySlip, generateSelectedEmployeeSalarySlip, getAllEmplyeeSalarySlipDetails, updateSalarySlipAmountDetails } from '../../../../api-services/employee-services';
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { currencyCode } from '../../../../services/constant';
import CurrencyCellRenderer from '../../../currency-cell-render/currency-cell-render';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip, } from '@mui/material';
import swal from 'sweetalert';
import { ButtonList } from 'sweetalert/typings/modules/options/buttons';
import { SnackbarService } from '../../../../services/behaviorSubject/behaviorSubject';
import ButtonWrapper from '../../../../widgets/button/button';



const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    border: '1px solid rgb(39,112,222)',
    borderRadious: "2px",
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 1, 0, 1)',
    // boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

interface SelectedYearMonth {
  month: string,
  year: string,
  monthName: string
}


const AdminsalarySlip = () => {
  const snackbarService = SnackbarService()
  const detailsYearField = detailsField()
  const [gridApi, setGridApi] = useState<any | null>(null)
  const [editableFieldRowIndex, setEditableFieldRowIndex] = useState<number | null>(null);
  const [selectedYearMonth, setSelectedYearMonth] = useState<SelectedYearMonth>({
    month: '',
    year: '',
    monthName: ''
  })

  const [rawData, setRawData] = useState([])
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState<Array<Record<string, number | string>>>([])
  const currency = currencyCode()
  const token = localStorage.getItem("loginToken") || ''
  const [selectedEmployee, setSelectedEmployee] = useState([])
  const fetchSalarySlipDetails = () => {
    getAllEmplyeeSalarySlipDetails(`${selectedYearMonth.month}/${selectedYearMonth.year}`).then((response: any) => {
      console.log(response)
      if (response?.data.success) {
        setRawData(response.data.payload)
      }
    })
  }
  useEffect(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    setSelectedYearMonth((preav: any) => {
      return { ...preav, ['month']: `${month}`, ['year']: `${year}`, ['monthName']: getMonthName(month) }
    })
  }, [])

  useEffect(() => {
    if (selectedYearMonth.month !== "" && selectedYearMonth.year !== "") {
      fetchSalarySlipDetails()
    }
  }, [selectedYearMonth])

  const fieldsToSum = [
    "bonus",
    "overTimeAmount",
    "basicSalary",
    "dearnessAllowance",
    "deductionAmount",
    "employeeStateInsurance",
    "grossSalary",
    "houseRentAllowance",
    "incomeTax",
    "medicalAllowance",
    "netSalary",
    "professionalTax",
    "providentFund",
    "specialAllowance",
    "travelAllowance"
  ];
  const calculateSum = () => {
    let calculatedSums: Record<string, number | string> = {};
    fieldsToSum.forEach((field: any) => {
      calculatedSums[field] = calculateFieldSum(rawData, field);
    });
    calculatedSums['designation'] = 'Total'
    setPinnedBottomRowData([calculatedSums])
  }

  function calculateFieldSum(arr: any, field: any) {
    return arr.reduce((sum: any, emp: any) => sum + emp[field], 0);
  }

  const onDownloadAll = () => {
    const status = selectedEmployee.every((x: any) => x.status === true)
    if (selectedEmployee.length > 0 && status === true) {
      downloadSelectedEmployeeSalarySlip(`${selectedYearMonth.month}/${selectedYearMonth.year}`, selectedEmployee).then((response: any) => {

        if (response?.data) {
          const linkSource = window.URL.createObjectURL(response.data);
          const downloadLink = document.createElement("a");
          const fileName = `${selectedYearMonth.month}/${selectedYearMonth.year}_salarySlip.zip`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          snackbarService.next({ data: { open: true, severity: 'success', message: "Selected Employee Salary Slip Downloaded" } })
        } else {

        }

      })
    } else if (status === false) {
      snackbarService.next({ data: { open: true, severity: 'error', message: 'Generate Salary Slip for Selected Employee' } })
    } else {
      snackbarService.next({ data: { open: true, severity: 'info', message: 'Select Employee for Download Salary Slip' } })
    }
  }

  const onGenerateAll = () => {
    if (selectedEmployee.length > 0) {
      generateSelectedEmployeeSalarySlip(`${selectedYearMonth.month}/${selectedYearMonth.year}`, selectedEmployee).then((response: any) => {
        if (response?.data.success) {
          fetchSalarySlipDetails()
          setSelectedEmployee([])
          snackbarService.next({ data: { open: true, severity: 'info', message: response.data.message } })
        } else {
          snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
        }
      })
    } else {
      snackbarService.next({ data: { open: true, severity: 'info', message: 'Select Employee for Generate Salary slip' } })
    }
  }




  console.log(selectedYearMonth)

  const columnDefs: any = [
    {
      headerName: "ID",
      field: "empId",
      width: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name",
      field: "empName",
      width: 225,

    },
    {
      headerName: "Designation",
      field: "designation",
      width: 275
    },
    {
      headerName: "Gross Salary",
      field: "grossSalary",
      width: 150,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
    },
    {
      headerName: "Basic Salary",
      field: "basicSalary",
      width: 150,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
    },
    {
      headerName: "House Rent Allowance",
      field: "houseRentAllowance",
      width: 175,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
    },
    {
      headerName: "Dearness Allowance",
      field: "dearnessAllowance",
      width: 175,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
    },
    {
      headerName: "Travel Allowance",
      field: "travelAllowance",
      width: 150,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
    },
    {
      headerName: "Medical Allowance",
      field: "medicalAllowance",
      width: 175,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
    },
    {
      headerName: "Special Allowance",
      field: "specialAllowance",
      width: 175,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
    },
    {
      headerName: "Income Tax",
      field: "incomeTax",
      width: 150,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
    },
    {
      headerName: "Professional Tax",
      field: "professionalTax",
      width: 175,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
    },
    {
      headerName: "Provident Fund",
      field: "providentFund",
      width: 175,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
    },
    {
      headerName: "Employee State Insurance",
      field: "employeeStateInsurance",
      width: 200,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
    },
    {
      headerName: "Over Time Amount",
      field: "overTimeAmount",
      width: 175,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
      editable: (params: any) => (params.node.rowIndex === editableFieldRowIndex) && params.data.status === false,
    },
    {
      headerName: "Bonus",
      field: "bonus",
      width: 175,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
      editable: (params: any) => (params.node.rowIndex === editableFieldRowIndex) && params.data.status === false,
    },
    {
      headerName: "Deduction Ammount",
      field: "deductionAmount",
      width: 200,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
    },
    {
      headerName: "Net Salary",
      field: "netSalary",
      width: 200,
      cellRenderer: (params: any) => (
        <CurrencyCellRenderer value={params.value} currencyCode={currency} />
      ),
    },
    {
      headerName: "Action",
      field: "",
      width: 250,
      cellRenderer: (params: any) => {
        console.log(params)
        if (params.node.rowPinned) {
          return (
            <>
              <div style={{ display: 'flex', gap: '10px' }}>
                <LightTooltip title='Generate All' placement='left'><AutorenewIcon className='custom-icon' style={{ color: 'var(--primaryColor)', marginTop: '6px' }} onClick={() => onGenerateAll()} /></LightTooltip>
                <LightTooltip title='Download All' placement='right'><FileDownloadIcon style={{ color: 'var(--primaryColor)', marginTop: '10px', fontSize: '20px' }} onClick={() => onDownloadAll()} /></LightTooltip>
              </div>
            </>
          )
        } else {
          const isDownloadVisible = params.data.status;
          console.log(isDownloadVisible)
          return (
            <>
              <div style={{ display: 'flex', gap: '10px' }}>
                {
                  !isDownloadVisible && <div className='edit-salary-slip'><ButtonWrapper buttonType='edit' onClick={() => onEdit(params)} tooltipTitle='Edit' tooltipPlacement='left' /></div>
                }
                {isDownloadVisible && <div className='edit-salary-slip'><ButtonWrapper buttonType='edit' tooltipTitle='Disable Edit' tooltipPlacement='left' /></div>}



                {!isDownloadVisible && (
                  <>
                    <LightTooltip title='Generate' placement='right'><AutorenewIcon className='custom-icon' style={{ color: 'var(--primaryColor)', marginTop: '6px' }} onClick={() => {
                      swal({
                        title: "Are you sure?",
                        text: `Do you want to Generate Salary Slip of ${params.data.empName}? After Salary Slip Generation You Can't Edit This Employee For This Month`,
                        icon: "warning",
                        buttons: true as unknown as ButtonList | (string | boolean)[] | undefined,
                        dangerMode: true,
                      })
                        .then((willDelete) => {
                          if (willDelete !== null) {
                            swal(`Poof! ${params.data.empName} Salary Slip has been Generated!`, {
                              icon: "success",
                            });
                            onGenerate(params)
                          } else {
                            swal(`Poof! ${params.data.empName}  has not been Generated!`, {
                              icon: "info",
                            });
                          }
                        });

                    }} /></LightTooltip>
                  </>
                )
                }
                {isDownloadVisible && (
                  <>
                    <LightTooltip title='Download' placement='right'><FileDownloadIcon style={{ marginTop: '10px', color: 'var(--primaryColor)', fontSize: '20px' }} onClick={() => onDownload(params)} /></LightTooltip>
                  </>
                )}

              </div >
            </>
          )
        }

      },
    }
  ]

  const onGenerate = (params: any) => {

    generateSalarySlip(params.data.empId, `${selectedYearMonth.month}/${selectedYearMonth.year}`).then((resp: any) => {
      if (resp?.data.success) {
        fetchSalarySlipDetails()
        snackbarService.next({ data: { open: true, severity: 'success', message: resp.data.message } })

      } else {
        snackbarService.next({ data: { open: true, severity: 'error', message: resp.data.message } })
      }
    })
  }
  const onDownload = (params: any) => {

    downloadSalarySlip(params.data.empId, `${selectedYearMonth.month}/${selectedYearMonth.year}`).then((response: any) => {
      if (response?.data) {
        const linkSource = window.URL.createObjectURL(response.data);
        const downloadLink = document.createElement("a");
        const fileName = `${params.data.empName}_${selectedYearMonth.month}/${selectedYearMonth.year}_salarySlip`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        snackbarService.next({ data: { open: true, severity: 'success', message: "Salary Slip Downloaded " } })
      }
    })
  }
  useEffect(() => {
    if (rawData.length > 0)
      calculateSum();
  }, [rawData]);
  const onGridReady = (params: any) => {
    setGridApi(params.api)
  }
  const getMonthName = (monthNumber: any) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  }

  const onMenuChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target
    setSelectedYearMonth((preav: any) => {
      return { ...preav, [name]: value.toString(), ['monthName']: getMonthName(name === "month" ? value : selectedYearMonth.month) }
    })
  }


  const onEdit = (params: any) => {
    setEditableFieldRowIndex(params.node.rowIndex)
    const colId = 'overTimeAmount';
    if (gridApi && params.node.rowIndex !== null) {
      gridApi.setFocusedCell(params.node.rowIndex, colId);
      setTimeout(() => {
        gridApi.startEditingCell({
          rowIndex: params.node.rowIndex,
          colKey: colId,
        });
      }, 0)

    }
  }

  const onCellValueChanged = (e: any) => {
    updateSalarySlipAmountDetails(e.data.empId, e.data).then((response: any) => {
      if (response?.data.success) {
        fetchSalarySlipDetails()
      }
    })
  }
  const getRowStyle = (params: any) => {
    if (params.node.rowPinned) {
      return {
        fontWeight: 700
      };
    }

  };

  const onSelectionChanged = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node: any) => {
      return { empId: node.data.empId, status: node.data.status }
    })
    setSelectedEmployee(selectedData)
  }
  const rowSelectionType = 'multiple'
  return (
    <div className='main'>
      <div className='main-container'>
        <div className='salary-slip-heading'>
          <h2>Salary Slip</h2>
          <div className='admin-search-container admin-salary-slip-search-container'>
            <FormControl fullWidth >
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                name='year'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Year"
                value={selectedYearMonth.year}
                onChange={onMenuChange}
                sx={{ height: '30px' }}
              >{
                  detailsYearField.salarySlipYear.map((item: any, index: number) => {
                    return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                  })
                }

              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Month</InputLabel>
              <Select
                name='month'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Month"
                value={selectedYearMonth.month}
                onChange={onMenuChange}
                sx={{ height: '30px' }}
              >
                {
                  detailsYearField.salarySlipMonth.map((item: any, index: number) => {
                    return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </div>
        </div>
        <div className='salary-slip-sub-container'>
          <div style={{ height: '100%' }}>
            <div className='salaryInfo-details-container'>Salary Slip of {selectedYearMonth.year} {selectedYearMonth.monthName}</div>
            <div className='grid-details-container'>
              <AgGridReact
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                rowData={rawData}
                editType={'fullRow'}
                className="ag-theme-alpine"
                rowSelection={rowSelectionType}
                suppressRowClickSelection={true}
                enableRangeSelection={true}
                stopEditingWhenCellsLoseFocus={true}
                singleClickEdit={true}
                onCellValueChanged={onCellValueChanged}
                pinnedBottomRowData={pinnedBottomRowData}
                getRowStyle={getRowStyle}
                onSelectionChanged={onSelectionChanged}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AdminsalarySlip