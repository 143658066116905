import React, { useState, useEffect } from 'react';
import './leave.css';
import { getAllLeaveDetails } from '../../../api-services/employee-services';
import { Avatar, Modal } from '@mui/material';
import { Box } from '@mui/system';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EditLeave from './editLeave/editLeave';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip } from '@mui/material';
import { Grid } from '@material-ui/core';
import ButtonWrapper from '../../../widgets/button/button';
import ModalWrapper from '../../../widgets/modal/modal';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const style = {

    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: 0.9,
        sm: 0.8,
        md: 400
    },
    bgcolor: 'white',
    boxShadow: 24,
    p: 2,
    borderRadius: 2

};

const AdminLeave = () => {
    // const leaveDetails = useSelector((state: any) => state.allEmployee);
    const [leaveDetails, setLeaveDetails] = useState([])
    const [searchInput, setSearchInput] = useState<string>('');
    const [filteredData, setFilteredData] = useState<any[]>(leaveDetails); // Initialize with all data
    const [isEditable, setIsEditable] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    const colDef = [
        { "label": "Id" },
        { "label": "Name" },
        { "label": "Casual Leave" },
        { "label": "Balance" },
        { "label": "Special Leave" },
        { "label": "Balance" },
        { "label": "Medical Leave" },
        { "label": "Balance" },
        { "label": "Vacation Leave" },
        { "label": "Balance" },
    ]
    const fetchData = () => {
        getAllLeaveDetails().then((response: any) => {
            if (response?.data.success) {
                setLeaveDetails(response.data.payload)
            }


        }).catch()
    }
    useEffect(() => {
        fetchData()
    }, [])

    const handleSearch = (searchText: string) => {
        setSearchInput(searchText);

        // Filter the data based on the search input
        const filtered = leaveDetails.filter((item: any) =>
            item.employee_name.toLowerCase().includes(searchText.toLowerCase())
        );

        setFilteredData(filtered);
    };

    useEffect(() => {
        // Initialize filteredData with all data when component mounts
        setFilteredData(leaveDetails);
    }, [leaveDetails]);

    const onLeaveCreate = () => {
        handleModalOpen()
        setIsEditable(false)
    }
    const onLeaveEdit = () => {
        handleModalOpen()
        setIsEditable(true)
    }
    const getLeaveDetails = (flag: boolean) => {
        if (flag)
            fetchData()
    }
    return (
        <>
            <div className='main'>
                <div className='main-container'>
                    <Grid container className='admin-leave-heading'>
                        <Grid xs={6} sm={6} className='admin-leave-sub-heading'>
                            <h2>Leave Details</h2>
                        </Grid>
                        <Grid xs={6} sm={6}>
                            <div className='leave-search-container'>
                                <div className='leave-sub-search-container'>
                                    <input
                                        style={{ border: 'none' }}
                                        type='search'
                                        id='filterText'
                                        placeholder='Search employee leave details...'
                                        value={searchInput}
                                        onChange={(e) => handleSearch(e.target.value)}
                                        className='search-input'
                                    />
                                </div>
                                <Avatar variant='circular' className='avatar'>
                                    <ButtonWrapper buttonType='edit' onClick={onLeaveEdit} tooltipTitle='Edit Leave' tooltipPlacement='left' />
                                </Avatar>
                            </div>
                            <ModalWrapper open={modalOpen} close={handleModalClose} children={<EditLeave onClose={handleModalClose} isEditable={isEditable} getLeaveDetails={getLeaveDetails} />} />
                        </Grid>
                    </Grid>

                    <div className='admin-leave-sub-container'>
                        <div style={{ height: '100%' }}>

                            <table className='table'>
                                <thead>
                                    <tr>
                                        {colDef.map((item: any, index: number) => {
                                            return <th key={index} className='td-width'>{item.label}</th>;
                                        })}
                                    </tr>
                                </thead>
                                {filteredData.length > 0 ? (
                                    <tbody>
                                        {filteredData.map((item: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td className='td-width'>{item.employee_id}</td>
                                                    <td className='td-width'>{item.employee_name}</td>
                                                    {item.leaveDetails.map((el: any, ind: number) => (
                                                        <React.Fragment key={ind}>
                                                            <td className='td-width'>{el.total}</td>
                                                            <td className='td-width'>{el.balance}</td>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td>No Data Found</td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminLeave;
