import { useNavigate, } from 'react-router-dom';
import './App.css';
import AllRoutes from './routes/all-routes';
import { useEffect, useState } from 'react';
import { setNavigate } from './api-services/api-services';
import { LoaderService, SnackbarService } from './services/behaviorSubject/behaviorSubject';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarWidget from './widgets/snackbar/snackbar';
import SideBar from './componant/layout/sideBar';
import jwt from 'jwt-decode';
import { JwtModel } from './model/token-model/token.model';
import globalRouter from './routes/global-router';

interface SnackbarServiceProps {
  data: {
    open: boolean,
    severity?: string,
    message?: string
  }
}
function App() {
  const navigate = useNavigate()
  globalRouter.navigate = navigate;


  // const token = localStorage.getItem('loginToken')
  // console.log(token, "Token")

  // useEffect(() => {
  //   if (token !== null) {
  //     setloginToken(token)
  //   } else {
  //     setloginToken('')
  //   }

  // }, [token])

  // useEffect(() => {
  //   if (loginToken !== undefined) {
  //     checkTokenExpiration(loginToken)
  //   } else {
  //     navigate('/')
  //   }
  // }, [loginToken])



  // function checkTokenExpiration(token: any) {
  //   const currentTimestamp = Math.floor(Date.now() / 1000);
  //   let user: JwtModel = jwt(token);
  //   const { iat, exp } = user;
  //   if (currentTimestamp >= iat && currentTimestamp <= exp) {
  //     if (user.roles === 'ADMIN') {
  //       navigate('/admin/dashboard');
  //     } else {
  //       navigate('/user/dashboard');
  //     }
  //   } else {
  //     navigate('/')
  //   }

  // }



  const [loader, setLoader] = useState<boolean>(false)
  const [snackbar, setSnackbar] = useState<SnackbarServiceProps>({ data: { open: false, severity: '', message: '' } })
  useEffect(() => {
    setNavigate(navigate)
  }, [navigate])

  const loaderService = LoaderService()
  const snackbarService = SnackbarService()
  useEffect(() => {
    loaderService.subscribe((subscribeValue: boolean) => setLoader(subscribeValue))
  }, [loaderService])

  useEffect(() => {
    snackbarService?.subscribe((subscribeValue: SnackbarServiceProps) => {
      setSnackbar((preav: any) => {
        return { ...preav, data: { open: subscribeValue.data.open, severity: subscribeValue.data.severity, message: subscribeValue.data.message } }
      })
    })

  }, [snackbarService])



  return (
    <div className="App">

      <AllRoutes />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackbarWidget data={{ open: snackbar.data.open, severity: snackbar.data.severity, message: snackbar.data.message }} />

    </div>
  );
}

export default App;
