import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useState } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { detailsField } from '../../../../services/employeeFieldType';
import './salarySlip.css'
import jwt from 'jwt-decode';
import { JwtModel } from '../../../../model/token-model/token.model';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { downloadSalarySlip, getEmployeeAllSalarySlip } from '../../../../api-services/employee-services';
import { SnackbarService } from '../../../../services/behaviorSubject/behaviorSubject';


interface SelectedYearMonth {
  year: string,
}

export const SalarySlip = () => {
  const snackbarService = SnackbarService()
  const detailsYearField = detailsField()
  const [rawData, setRawData] = useState([])
  const [selectedYear, setSelectedYear] = useState<SelectedYearMonth>({ year: '' })
  const token = localStorage.getItem("loginToken") || ''
  let user: JwtModel = jwt(token);
  console.log(user)
  useEffect(() => {
    if (selectedYear.year !== '') {
      getEmployeeAllSalarySlip(selectedYear.year).then((res: any) => {
        console.log()
        if (res?.data) {
          if (res.data.success === true) {
            setRawData(res.data.payload)
          } else {
            console.log(res.data)
            setRawData(res.data.payload)
            snackbarService.next({ data: { open: true, severity: 'warning', message: `${res.data.message} please contact to responsible authority!` } })
          }
        }
      }).catch((err: any) => {
        console.log(err)
      })
    }
  }, [selectedYear])
  useEffect(() => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    setSelectedYear((preav: any) => {
      return { ...preav, ['year']: currentYear.toString() }
    })
  }, [])
  const onDownloadSalarySlip = (params: any) => {
    const month = new Date(`${params.data.month} 1,${params.data.year}`).getMonth() + 1
    const date = `${month}/${params.data.year}`
    console.log(user.employee_id, date)
    downloadSalarySlip(user.employee_id, date).then((res: any) => {
      if (res?.data) {
        const linkSource = window.URL.createObjectURL(res.data);
        const downloadLink = document.createElement("a");
        const fileName = `${user.name}_${date}_salarySlip`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        snackbarService.next({ data: { open: true, severity: 'success', message: "Salary Slip Downloaded" } })
      }
    })

  }
  const columnDefs: any = [
    {
      headerName: "Year",
      field: "year",
      width: 422,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Month",
      field: "month",
      width: 422,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Action",
      field: "",
      width: 100,
      cellRenderer: (params: any) => {
        return (
          <>
            <FileDownloadIcon style={{ color: 'var(--primaryColor)', marginTop: '10px', fontSize: '20px' }} onClick={() => onDownloadSalarySlip(params)} />

          </>
        )
      },
    }

  ]
  const onMenuChange = (e: SelectChangeEvent) => {
    const { value, name } = e.target
    setSelectedYear((preav: any) => {
      return { ...preav, [name]: value.toString() }
    })
  }


  return (
    <div className='main'>
      <div className='main-container'>
        <div className='heading4'>
          <h2>Salary Slip</h2>
          <div className='search-container'>
            <FormControl fullWidth >
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                name='year'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Year"
                value={selectedYear.year}
                onChange={onMenuChange}
                sx={{ height: '30px' }}
              >{
                  detailsYearField.salarySlipYear.map((item: any, index: number) => {
                    return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                  })
                }

              </Select>
            </FormControl>
          </div>
        </div>
        <div className='salary-sub-container'>
          <div style={{ height: '100%' }}>
            <div className='user-salaryInfo-details-container'>Salary Slip of {selectedYear.year}</div>
            <div className='user-salary-grid-details-container'>
              <AgGridReact
                columnDefs={columnDefs}
                rowData={rawData}
                editType={'fullRow'}
                className="ag-theme-alpine"
                suppressRowClickSelection={true}
                stopEditingWhenCellsLoseFocus={true}
                singleClickEdit={true}

              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
