import React, { useEffect, useState } from 'react'
import "./attendance.css"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import DownloadIcon from '@mui/icons-material/Download';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Grid, Stack, TextField, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { utils } from 'xlsx';
import { writeFile } from 'xlsx';
import { getAllEmployeeAttendance } from '../../../api-services/employee-services';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formateTimeZone } from '../../../services/constant';
import { Modal, Typography } from '@mui/material';
import { Box, height } from '@mui/system';
import AttendanceDetails from '../../user/attendance-details/attendance-details';
import dayjs from 'dayjs';
import { modalStyle } from '../../../style/style';
import ButtonWrapper from '../../../widgets/button/button';
import ModalWrapper from '../../../widgets/modal/modal';



const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 1, 0, 1)',
        fontSize: 11,
    },
}));

interface EmployeeAttendance {
    employee_id: string;
    employee_name: string;
    designation: string;
    attendanceDetails: AttendanceDetail[];
}
interface AttendanceDetail {
    agenda: string
    clockIn: string
    clockInDate: string
    clockOut: string
    employee_id: string
    workUpdate: string
    workingHours: string
}


const AdminAttendance = () => {
    const style = modalStyle()
    const inputField = [
        { name: 'startDate', label: 'Form Date', type: 'date' },
        { name: 'endDate', label: 'To Date', type: 'date' }
    ]
    const [allAttendance, setAllAttendance] = useState<EmployeeAttendance[]>([])
    const [sortedallAttendance, setSortedAllAttendance] = useState<EmployeeAttendance[]>([])
    const [downloadAttendanceData, setDownloadAttendanceData] = useState<EmployeeAttendance[]>([])
    const [openModal, setOpen] = useState<boolean>(false);
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => setOpen(false);
    const [selectedDate, setSelectedDate] = useState<any>({
        startDate: '',
        endDate: ''
    });
    const [showDownloadButton, setShowDownloadButton] = useState(false)
    const [elements, setElement] = useState<any>()

    const fetchData = async () => {
        getAllEmployeeAttendance().then((response: any) => {
            if (response?.data.success) {
                setAllAttendance(response.data.payload)
                filterDataForSelectedDate(null, null, response.data.payload)
                console.log(response.data)

            }
        })
    }



    const formateDate = (data: any) => {
        const currentDate = data.split('/', 3)
        const FormateDate = `${currentDate[2]}-${currentDate[1]}-${currentDate[0]}`
        const newFormateDate = new Date(FormateDate)
        return newFormateDate;
    }
    useEffect(() => {
        fetchData()



    }, [])

    useEffect(() => {
        if (selectedDate && selectedDate.startDate !== '' && selectedDate.endDate !== '') {
            const startDate = new Date(selectedDate.startDate);
            startDate.setHours(0, 0, 0, 0)
            const endDate = new Date(selectedDate.endDate);
            endDate.setHours(0, 0, 0, 0)
            filterDataForSelectedDate(startDate, endDate)

        }
    }, [selectedDate])


    const filterDataForSelectedDate = (startDateData?: any, endDateData?: any, response?: EmployeeAttendance[]) => {
        if (response !== undefined) {
            var attendanceData = response
            setSortedAllAttendance(response)
        } else {
            var attendanceData = allAttendance
        }
        const today = new Date()
        let startDate: Date = new Date(startDateData !== null ? startDateData : today)
        let endDate: Date = new Date(endDateData !== null ? endDateData : today)
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(0, 0, 0, 0)
        const timeDifference = endDate.getTime() - startDate.getTime();
        const numberOfDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;
        if (startDate && endDate) {
            const filteredData = attendanceData?.map((employee: any) => ({
                ...employee,
                attendanceDetails: employee.attendanceDetails.filter((detail: any) => {
                    const detailDate = formateDate(detail.clockInDate);
                    detailDate.setHours(0, 0, 0, 0)
                    if (numberOfDays > 1) {
                        return detailDate >= startDate && detailDate <= endDate;
                    } else {
                        return detailDate.getTime() === startDate.getTime() && detailDate.getTime() === endDate.getTime();
                    }

                }).sort((a: any, b: any) => {
                    const dateA = new Date(a.clockInDate);
                    const dateB = new Date(b.clockInDate);
                    return dateA.getTime() - dateB.getTime();
                }),
            }));
            if (numberOfDays > 7) {
                setShowDownloadButton(true);
                setDownloadAttendanceData(filteredData)
            } else if (numberOfDays <= 7) {
                setShowDownloadButton(false);
                setSortedAllAttendance(filteredData)
            }
        }
    };

    const handleDownloadAttendanceDetails = (e: any) => {
        const header = ['Employee Id', 'Employee Name', 'Designation', 'Attendance'];

        const ws = utils.aoa_to_sheet([header]);
        const newData: any = [];
        const encounteredEmployeeIds: Set<string> = new Set();
        downloadAttendanceData.map((employee: any) => {
            // Extract employee information
            const employeeId = employee.employeeId;
            const employeeName = employee.employeeName;
            const designation = employee.designation;
            const rowData = [employeeId, employeeName, designation, 'Date', 'In Time', 'Out Time', 'Working Hours'];
            newData.push(rowData)
            newData.push(["", "", "", "", "", "", ""])
            // Iterate over the "attendanceDetails" array for each employee
            employee.attendanceDetails.forEach((attendanceDetail: any) => {
                const date = attendanceDetail.clockInDate;
                const inTime = formateTimeZone(attendanceDetail.clockIn);
                const outTime = formateTimeZone(attendanceDetail.clockOut);
                const workingHour = attendanceDetail.workingHours;

                // Create a row of data and push it to newRowData
                const employeeInfoTuple = `${employeeId}-${employeeName}-${designation}`;
                if (!encounteredEmployeeIds.has(employeeInfoTuple)) {
                    const rowData = ["", "", "", date, inTime, outTime, workingHour];
                    newData.push(rowData);
                    encounteredEmployeeIds.add(employeeInfoTuple);
                } else {
                    const rowData = ["", "", "", date, inTime, outTime, workingHour];
                    newData.push(rowData);
                }

            });
            newData.push(["", "", "", "", "", "", ""])
        });


        // Create a worksheet with the header and data
        utils.sheet_add_aoa(ws, newData, { origin: 'A2' });

        ws['!merges'] = [{ s: { r: 0, c: 3 }, e: { r: 0, c: 6 } }];
        // Create a new workbook and add the worksheet
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Attendance Data');

        // Save the workbook as an Excel file
        writeFile(wb, 'attendance_data.xlsx');

    }

    useEffect(() => {
        const startDate = new Date(selectedDate.startDate);
        startDate.setHours(0, 0, 0, 0)
        let endDate: Date | undefined = undefined;
        if (selectedDate.endDate !== '') {
            endDate = new Date(selectedDate.endDate);
            endDate.setHours(0, 0, 0, 0)
        }
        if (endDate) {
            if (startDate > endDate) {
                setSelectedDate((preav: any) => {
                    return { ...preav, endDate: '' }
                })
            }
        }


    }, [selectedDate.endDate, selectedDate.startDate])


    function formatDateToCustomFormat(inputDate: any) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const customFormat = `${year}-${month}-${day}`; return customFormat;
    }

    const handleDate = (e: any, field: any) => {
        const value = formatDateToCustomFormat(e.$d.toLocaleDateString("en-US")).toString()
        setSelectedDate((preav: any) => {
            return { ...preav, [field]: value }
        })

    }

    const handleDetails = (element: any) => {
        handleOpen()
        setElement(element)
    }
    console.log(sortedallAttendance, "Sort Attendance")

    return (
        <>

            <div className='main'>
                <div className="main-container">
                    <Stack height='100%' >
                        <div className='header-container'>

                            <h2>Attendance List</h2>

                            <div className='header-sub-container'>
                                <div className='date-range-sub-container'>
                                    {
                                        inputField.map((item: any, index: number) => {
                                            return <div key={index} className='attendance-date-container'>
                                                <div className='attendance-dates-container'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <MobileDatePicker
                                                            label={item.label}
                                                            format='DD/MM/YYYY'
                                                            minDate={item.name === 'endDate' && selectedDate.startDate ? dayjs(selectedDate.startDate) : undefined}
                                                            onChange={(e) => handleDate(e, item.name)}
                                                            value={selectedDate[item.name]}
                                                            closeOnSelect={true}
                                                        />
                                                    </LocalizationProvider>
                                                </div >
                                            </div>
                                        })
                                    }
                                </div>
                                <div className='button-container'>
                                    {showDownloadButton ? <LightTooltip title='Download Excel'><Button
                                        variant='contained'
                                        onClick={(e) => handleDownloadAttendanceDetails(e)}
                                        className='download-button-container'
                                    ><DownloadIcon /></Button></LightTooltip> : ''}
                                </div>
                            </div>

                        </div>
                        <div className='attendance-body-grid'>
                            <div style={{ height: '100%' }}>
                                <div className='attendance-sub-container'>
                                    <div style={{ height: '100%' }}>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th className='table-row-head1'>Id</th>
                                                    <th className='table-row-head1'>Name</th>
                                                    <th className='table-row-head1'>Designation</th>
                                                    <th className='table-row-head'>
                                                        <p className="width20">Date</p>
                                                        <p className="width16">In</p>
                                                        <p className="width16">Out</p>
                                                        <p className="width16">Break</p>
                                                        <p className="width16">Working</p>
                                                        {/* <p className="width20">Status</p> */}
                                                        <p className="width16">Details</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sortedallAttendance?.length > 0 ? sortedallAttendance.map((ele: any, ind: number) => {
                                                    return (
                                                        <tr key={ind}>
                                                            <td className='table-row-head1'>{ele.employeeId}</td>
                                                            <td className='table-row-head1'>{ele.employeeName}</td>
                                                            <td className='table-row-head1'>{ele.designation}</td>
                                                            {ele.attendanceDetails?.map((element: any, i: any) => (
                                                                <td className='table-row-head' key={i}>
                                                                    <p className="width20">{element.clockInDate}</p>
                                                                    <p className="width16">{formateTimeZone(element.clockIn)}</p>
                                                                    <p className="width16">{formateTimeZone(element.clockOut)}</p>
                                                                    <p className="width16">{element.breakHours}</p>
                                                                    <p className="width16">{element.workingHours}</p>
                                                                    {/* <p className="width20">{element.status}</p> */}
                                                                    <p className="width16">
                                                                        <p className='button-class-top'>
                                                                            <ButtonWrapper title='View' onClick={() => handleDetails(element)} />
                                                                        </p>
                                                                    </p>

                                                                </td>
                                                            ))}
                                                        </tr>
                                                    );
                                                }) : null}
                                                <ModalWrapper open={openModal} close={handleClose} children={<AttendanceDetails data={elements} onClose={handleClose} />} />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Stack>

                </div>

            </div >
        </>
    )
}

export default AdminAttendance