import React from 'react'
import { AiFillCloseCircle, AiOutlinePlusSquare } from 'react-icons/ai';
import './leave-details.css'
import CloseIcon from '@mui/icons-material/Close';

interface LeaveDetailsProps {
    items: any;
    onClose: () => void
}
const LeaveDetails = (props: LeaveDetailsProps) => {
    console.log(props)
    return (
        <div className='main'>

            <div className='leave-details-sub-container'>


                <CloseIcon
                    className='leave-details-close-button'
                    onClick={props.onClose}
                />
                <div className='leave-details-heading'>{`${props.items.leaveType}(${props.items.leaveBreaks.balance} Days)`}</div>
                <div className='leave-containers'>
                    <label style={{ marginTop: '5px' }}>Type</label>
                    <div>{props.items.leaveType}</div>
                    <br />
                    <label className='leave-containers-label'>Balance</label>
                    <div className='leave-details-balance-container'>
                        <div className={props.items.leaveBreaks.balance !== 0 ? 'leave-balance-show-container' : 'leave-balance-show-container1'}>{`${props.items.leaveBreaks.balance} Days`}</div>
                    </div>
                    <div className="leave-details-break-main-container">
                        <div className='leave-details-break-sub-container'>
                            <div>
                                <label className='leave-containers-label'>Earned</label>
                                <div>{props.items.leaveBreaks.earned}</div>
                            </div>
                            <div className='leave-details-break-container'>
                                <label className='leave-containers-label'>Entitled</label>
                                <div>{props.items.leaveBreaks.entitled}</div>
                            </div>
                            <div className='leave-details-break-container'>
                                <label>Applied</label>
                                <div>0</div>
                            </div>
                        </div>
                        <div className='leave-details-break-sub-container'>
                            <div>
                                <label>Carried Over</label>
                                <div>0</div>
                            </div>
                            <div className='leave-details-break-container'>
                                <label>Taken</label>
                                <div>{props.items.leaveBreaks.taken}</div>
                            </div>
                            <div className='leave-details-break-container'>
                                <label>Reject</label>
                                <div>0</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaveDetails