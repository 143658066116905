import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import './holiday.css'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import FetchApi from '../../../fetch-api/fetch-api';
import { Stack } from '@mui/system';


const UserHoliday = () => {
  const fetchApi = FetchApi()
  const [rowData, setRowData] = useState([])
  const state = useSelector((state: any) => state.allEmployee)

  useEffect(() => {
    if (state.holiday.length > 0) {
      setRowData(state.holiday)
      console.log(state.holiday, "Holiday")
    }
  }, [state])
  useEffect(() => {
    fetchApi.fetchHoliday()
  }, [])
  const [columnDefs]: any[] = useState([
    {
      headerName: "S.No",
      field: "sno",
      width: 100,
      cellStyle: () => ({
        alignItems: "center",

      })
    },
    {
      headerName: "Date",
      field: "date",
      width: 416,
      cellStyle: () => ({
        alignItems: "center",

      })
    },
    {
      headerName: "Reasons",
      field: "reason",
      width: 416,
      headerClass: 'text-center',
      cellStyle: () => ({
        alignItems: "center",
      }),

    }
  ])

  return (
    <div className='main'>
      <div className='main-container' >
        <Stack height='100%'>
          <div className='heading4'>
            <h2>Holiday</h2>
          </div>
          <div className='holiday-sub-container'>

            <div className='holiday-list-container'>
              {<div className="table-wrapper">
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  className="ag-theme-alpine"
                />
              </div>
                //  : <div className="holiday-nodata-container"><WarningIcon className='holiday-nodata-alert-icon' /><div>{"Data not available"}</div><WarningIcon sx={{ color: "var(--dangerColor)" }} /></div>
              }
            </div>

          </div>
        </Stack>
      </div>
    </div>
  )
}

export default UserHoliday