import React, { useEffect, useState, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { detailsField } from '../../services/employeeFieldType';
import './addTeamProject.css'
import { Button } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import FetchApi from '../../fetch-api/fetch-api';
import { useSelector } from 'react-redux';
import { createTeam, searchEmployeeByLetter, updateTeamDetails } from '../../api-services/employee-services';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { checkLoginType } from '../../services/loginType';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import jwt from 'jwt-decode';
import { JwtModel } from '../../model/token-model/token.model';
import { SnackbarService } from '../../services/behaviorSubject/behaviorSubject';

interface State extends SnackbarOrigin {
  open: boolean;
  severity: AlertColor
}

interface FieldValueProps {
  projectId?: string,
  projectName: string,
  projectClient: string,
  projectDetails: string,
  projectDemoDate: string,
  projectWorkingStatus: string,
  projectStartDate: string,
  projectDeliveryDate: string,
  projectLead: any,
  projectFrontEndMembers: string[],
  projectBackEndMembers: string[],
  projectRemark: string,
}
interface addTeamProjectProps {
  onClose: () => void;
  actionStatus?: boolean;
  data?: any;
  handleModalClose?: (flag: boolean) => void;
}

const AddTeamProject = (props: addTeamProjectProps) => {
  const snackbarService = SnackbarService()
  const isAdmin = checkLoginType()
  const employeeState = useSelector((state: any) => state.allEmployee)
  const fetchApi = FetchApi()
  const [isSearchCall, setIsSearchCall] = useState<boolean>(false)
  const [fieldValue, setFieldValue] = useState<FieldValueProps>({
    projectName: "",
    projectClient: "",
    projectDetails: "",
    projectDemoDate: "",
    projectWorkingStatus: "",
    projectStartDate: "",
    projectDeliveryDate: "",
    projectLead: { "name": '', "employee_id": '' },
    projectFrontEndMembers: [],
    projectBackEndMembers: [],
    projectRemark: ''
  })

  const [optionData, setOptionData] = useState<any[]>([]);
  const [propsDatas, setPropsData] = useState<FieldValueProps>()
  const field = detailsField().newProjectdetails
  const { control, handleSubmit } = useForm();
  const [propsState, setProps] = useState<any>()
  const token = localStorage.getItem("loginToken") || ''
  console.log(token, "Token Check")
  let user: JwtModel = jwt(token);
  const [loader, setLoader] = useState<boolean>(false);
  const inputFieldRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setProps(props)
  }, [props])

  useEffect(() => {
    if (propsDatas !== undefined) {
      const output_data: FieldValueProps = {
        "projectId": propsDatas["projectId"],
        "projectName": propsDatas["projectName"],
        "projectClient": propsDatas["projectClient"],
        "projectDetails": propsDatas['projectDetails'],
        "projectDemoDate": propsDatas['projectDemoDate'],
        "projectWorkingStatus": propsDatas['projectWorkingStatus'],
        "projectStartDate": propsDatas["projectStartDate"],
        "projectDeliveryDate": propsDatas["projectDeliveryDate"],
        "projectLead": propsDatas.projectLead[0],
        "projectFrontEndMembers": propsDatas.projectFrontEndMembers,
        "projectBackEndMembers": propsDatas.projectBackEndMembers,
        "projectRemark": propsDatas['projectRemark']
      }
      setFieldValue(output_data)

    }
  }, [propsDatas])
  useEffect(() => {
    if (propsState?.data?.data && !isSearchCall) {
      setIsSearchCall(true)
      const propsData = { ...propsState?.data.data }
      searchApiCall(propsData.projectLed, true).then((res: any) => {
        propsData.projectLead = res
        setPropsData(propsData)
      })
    } else {
    }
  }, [propsState])

  const handleField = (e: any) => {
    const { value, name } = e.target
    const selectedValues = value
    if (name !== 'projectMembersId' || name !== 'projectLeadId') {
      setFieldValue((preav: any) => {
        return { ...preav, [name]: value }
      })
    } else if (name === 'projectMembersId') {
      setFieldValue((prevPersonName) => ({
        ...prevPersonName,
        projectMembersId: Array.isArray(selectedValues) ? selectedValues : [selectedValues]
      }));
    } else {
      setFieldValue((prevPersonName) => ({
        ...prevPersonName,
        projectLeadId: selectedValues
      }));
    }

  }

  const onSubmit = (data: any) => {
    if (!propsState.actionStatus) {
      createTeam(fieldValue).then((response: any) => {
        if (response !== null && response?.data.success) {
          snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
          setFieldValue({
            projectName: "",
            projectClient: "",
            projectDetails: "",
            projectDemoDate: "",
            projectWorkingStatus: "",
            projectStartDate: "",
            projectDeliveryDate: "",
            projectLead: [],
            projectFrontEndMembers: [],
            projectBackEndMembers: [],
            projectRemark: ''
          })
          fetchApi.fetchAdminTeam()
        } else {

          snackbarService.next({ data: { open: true, severity: 'error', message: response?.data.message } })
        }
      })
    } else {
      fieldValue.projectFrontEndMembers.forEach((member: any) => {
        if (member.designation !== undefined) {
          member.employee_id = member.empId;
          delete member.designation;
          delete member.empId;
        }
      });
      updateTeamDetails(fieldValue.projectId, fieldValue).then((response: any) => {
        if (response?.data.success) {
          snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
          if (isAdmin || user.roles === 'COREMEMBERS') {
            fetchApi.fetchAdminTeam()
          } else {
            fetchApi.fetchUserTeam()
          }

          props.onClose()
        } else {
          snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
        }
      })
    }

  }

  const handleAutocompleteChange = (e: any, newValue: any, field: any) => {
    if (field === 'projectLead') {
      setFieldValue((prevPersonName) => ({
        ...prevPersonName,
        projectLead: newValue
      }));
    } else if (field === 'projectFrontEndMembers') {
      setFieldValue((prevPersonName) => ({
        ...prevPersonName,
        projectFrontEndMembers: newValue
      }));
    } else {
      setFieldValue((prevPersonName) => ({
        ...prevPersonName,
        projectBackEndMembers: newValue
      }));
    }



  }
  const searchApiCall = async (newValue: any, flag?: boolean) => {
    const result = newValue
    let val;



    try {
      const response: any = await searchEmployeeByLetter(result);

      if (response && response?.data.success) {
        setLoader(false)
        if (flag === true) {
          val = response.data.payload;
        } else {
          setOptionData(response.data.payload);
        }
      } else {
        setOptionData([]);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
    if (flag === true) {
      return val
    }
  }

  const getData = (d1: any, d2: any) => {
    searchApiCall(d1, false)
  }
  const debounceTime = function <T>(fn: Function, delay: number) {
    let timer: NodeJS.Timeout;
    return function (this: T, ...args: any[]) {
      setLoader(true)
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    }
  }

  const onSearchData = debounceTime(getData, 1000);

  const handleInputChange = (e: any, newValue: any) => {
    if (e && newValue !== "") {
      onSearchData(newValue)
    } else {
      setOptionData([])
    }
  }

  console.log(fieldValue, "Field vAlue")

  return (
    <div className='team-create-container'>
      <div className='team-create-sub-container'>
        <div className='heading1'>
          <h2>{!propsState?.actionStatus ? 'Add' : 'Edit'} Project Details</h2>
          <CloseIcon
            className='team-create-close-button'
            onClick={propsState?.onClose}
          />
        </div>

        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '90%' }}>
          <div style={{ height: '100%' }}>
            <div className='team-create-form-container'>

              {field.map((ele: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    {ele.type !== 'select' ? <>
                      < label className='team-form-container-label'>{ele.label}</label>
                      <Controller
                        name={ele.name}
                        control={control}
                        render={({ field }) => (

                          <>
                            <input
                              type={ele.type}
                              {...field}
                              onChange={handleField}
                              value={fieldValue[ele.name as keyof FieldValueProps]}
                              required
                            />

                          </>
                        )}
                      />
                    </> : <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label className='label'>{ele.label}</label>
                      <Autocomplete
                        limitTags={2}
                        multiple={(ele.name === 'projectFrontEndMembers' || ele.name === 'projectBackEndMembers') ? true : false}
                        id="checkboxes-tags-demo"
                        options={loader === true ? [{ name: 'Loading...' }] : optionData}
                        disableCloseOnSelect
                        disablePortal
                        getOptionLabel={(option) => option?.name || ''}
                        onChange={(e, newValue) => handleAutocompleteChange(e, newValue, ele.name)}
                        onInputChange={(e, newValue) => handleInputChange(e, newValue)}
                        value={ele.name === 'projectFrontEndMembers' ? fieldValue.projectFrontEndMembers :
                          ele.name === 'projectBackEndMembers' ? fieldValue.projectBackEndMembers :
                            fieldValue.projectLead}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            {option.name}
                          </li>
                        )}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField {...params} inputRef={inputFieldRef} required={!props.actionStatus && fieldValue.projectBackEndMembers.length <= 0 || fieldValue.projectFrontEndMembers.length <= 0} />
                        )}
                      />


                    </div>}
                  </React.Fragment>
                )
              })

              }
            </div>
            <div style={{ height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {/* <Button
                id="submit-btn"
                variant='contained'
                type="submit"
              >{propsState?.actionStatus ? 'Update' : 'Submit'}</Button> */}
              <button
                type='submit'
                className='button-style'
              >{propsState?.actionStatus ? 'Update' : 'Submit'}</button>
            </div>
          </div>
        </form>
      </div >
    </div >
  )
}

export default AddTeamProject