import React, { useState, useEffect } from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import swal from 'sweetalert';
import { ButtonList } from 'sweetalert/typings/modules/options/buttons';
import './cell-renderer.css'
import { Button } from '@mui/material';
import { approvedLeave, approvedWorkFromHome, rejectLeave, rejectWorkFromHome } from '../../api-services/employee-services';
import { FcApproval } from "react-icons/fc";
import { AiFillCloseCircle } from "react-icons/ai";
import './approve-reject-cell.css'


const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

interface CustomCellRendererProps {
    value?: any;
    handleEditEmployees?: (param: any) => void;
    actionStaus?: boolean;
}


const ApproveRejectCell: React.FC<CustomCellRendererProps> = ({ value, handleEditEmployees, actionStaus }) => {
    const [leaveStatus, setLeaveStatus] = useState("")
    console.log(value, "Value")
    useEffect(() => {
        if (value) {

            setLeaveStatus(value.leaveStatus)

        }
    }, [value])

    const handleApprove = (para: any) => {
        if (!actionStaus) {
            approvedLeave(para).then((response: any) => {
                if (response?.data.success) {
                    if (handleEditEmployees)
                        handleEditEmployees(true)
                }
            })
        } else {
            approvedWorkFromHome(para).then((response: any) => {
                if (response?.data.success) {
                    if (handleEditEmployees)
                        handleEditEmployees(true)
                }
            }).catch((err) => {

            });
        }

    }

    const handleReject = (para: any) => {
        if (!actionStaus) {
            rejectLeave(para).then((response: any) => {
                if (response?.data.success) {
                    if (handleEditEmployees)
                        handleEditEmployees(true)
                }
            })
        } else {
            rejectWorkFromHome(para).then((response: any) => {
                if (response?.data.success) {
                    if (handleEditEmployees)
                        handleEditEmployees(true)
                }
            }).catch((err) => {

            });
        }

    }

    return (
        <>
            {
                leaveStatus === 'approved' || leaveStatus === 'rejected' ? <div className={leaveStatus === 'approved' ? 'approve-status-container' : 'reject-status-container'}>{leaveStatus === 'approved' ? <FcApproval /> : <AiFillCloseCircle color='red' />}{leaveStatus}</div> :
                    <>
                        <Button size="small" variant="contained"
                            onClick={() => {
                                swal({
                                    title: "Are you sure?",
                                    text: `Do you want to approve this leave?`,
                                    icon: "warning",
                                    buttons: true as unknown as ButtonList | (string | boolean)[] | undefined,
                                    dangerMode: true,
                                })
                                    .then((willDelete) => {
                                        if (willDelete !== null) {
                                            swal("This leave has been Approved!", {
                                                icon: "success",
                                            });
                                            if (value.hasOwnProperty('leaveId')) {
                                                handleApprove(value.leaveId);
                                            } else if (value.hasOwnProperty('wfhId')) {
                                                handleApprove(value.wfhId);
                                            }
                                        }
                                    });
                            }}
                        >Accept</Button>
                        <Button size="small" variant="contained"
                            onClick={() => {
                                swal({
                                    title: "Are you sure?",
                                    text: `Do you want to reject this leave?`,
                                    icon: "warning",
                                    buttons: true as unknown as ButtonList | (string | boolean)[] | undefined,
                                    dangerMode: true,
                                })
                                    .then((willDelete) => {
                                        if (willDelete !== null) {
                                            swal("This leave has been Rejected!", {
                                                icon: "info",
                                            });

                                            if (value.hasOwnProperty('leaveId')) {
                                                handleReject(value.leaveId)
                                            } else if (value.hasOwnProperty('wfhId')) {
                                                handleReject(value.wfhId);
                                            }
                                        }
                                    });
                            }}
                        >Reject</Button>
                    </>
            }
        </>
    )
}

export default ApproveRejectCell;

