import React, { useEffect, useState } from 'react'
import './editLeave.css'
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { detailsField } from '../../../../services/employeeFieldType';
import { getLeave, updateLeave } from '../../../../api-services/employee-services';
import { SnackbarService } from '../../../../services/behaviorSubject/behaviorSubject';

interface FieldValueProps {
    casual: string,
    special: string,
    medical: string,
    id: number
}
interface editLeaveProps {
    onClose: () => void,
    isEditable: boolean,
    getLeaveDetails: (params: boolean) => void
}

const EditLeave = (props: editLeaveProps) => {
    const snackbarService = SnackbarService()
    const field = detailsField().createLeave
    const [fieldValue, setFieldValue] = useState<FieldValueProps>({
        casual: '',
        special: '',
        medical: '',
        id: 0
    })

    useEffect(() => {
        getLeave().then((response: any) => {
            if (response.data.success) {
                setFieldValue((preav: any) => {
                    return { ...preav, ...response.data.payload[0] }

                })
            }
        })
    }, [])
    const { control, handleSubmit } = useForm();

    const handleLeaveType = (e: any) => {
        const { name, value } = e.target
        setFieldValue(preav => {
            return { ...preav, [name]: Number(value) }
        })
    }

    const onSubmit = (data: any) => {
        const updateData: FieldValueProps[] = [{ ...fieldValue }]
        updateData.forEach((element: any) => {
            if (element.id)
                delete element.id
        })
        updateLeave(updateData[0], fieldValue.id).then((response: any) => {
            if (response.data?.success) {
                snackbarService?.next({ data: { open: true, severity: 'success', message: response.data.message } })
                props.getLeaveDetails(true)
            } else {
                snackbarService?.next({ data: { open: true, severity: 'error', message: response.data.message } })
            }
        })
        // const deleteId = delete updateData.id

        // let modifiedObject = Object.fromEntries(Object.entries(fieldValue).filter(([key]) => key !== 'id'));
    }
    return (
        <div className='main'>
            <div className='create-leave-heading-container'>

                <h3>Edit Leave</h3>
                <CloseIcon className='create-leave-close-button' onClick={() => props.onClose()} />

            </div>
            <div className='create-leave-sub-container'>
                <form className='create-leave-form-container' onSubmit={handleSubmit(onSubmit)}>
                    <div className='create-leave-field-container'>
                        {
                            field.map((element: any, index: number) => {
                                return <div className='create-leave-field-sub-container' key={index}>
                                    <label>{element.label}</label>
                                    <Controller
                                        name={element.name}
                                        control={control}
                                        render={({ field }) => (

                                            <>
                                                <input
                                                    type={element.type}
                                                    {...field}
                                                    onChange={handleLeaveType}
                                                    value={fieldValue[element.name as keyof FieldValueProps]}
                                                    required
                                                />

                                            </>
                                        )}
                                    />
                                </div>
                            })
                        }

                    </div>
                    <div className='create-leave-button-container'>
                        <Button
                            id="submit-btn"
                            variant='contained'
                            type="submit"
                        >  Update</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditLeave
