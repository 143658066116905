import { BehaviorSubject } from 'rxjs';

export const LoaderService = (() => {
    let behaviorSubject: BehaviorSubject<boolean> | null = null;
    return () => {
        if (behaviorSubject === null) {
            behaviorSubject = new BehaviorSubject(false)
        }
        return behaviorSubject
    }
})();
interface SnackbarBehaviorSubjectServiceProps {
    data: {
        open: boolean,
        severity?: string,
        message?: string
    }
}
export const SnackbarService = (() => {
    let snackbarBehaviorSubject: BehaviorSubject<SnackbarBehaviorSubjectServiceProps>;
    return () => {
        if (!snackbarBehaviorSubject) {
            snackbarBehaviorSubject = new BehaviorSubject<SnackbarBehaviorSubjectServiceProps>({ data: { open: false, severity: '', message: '' } })
        }
        return snackbarBehaviorSubject
    }
})();
