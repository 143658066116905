import axios from "axios";
import { LoaderService } from "../services/behaviorSubject/behaviorSubject";
import { RedirectToHomePage } from "../services/constant";
import { useNavigate } from "react-router";
import { JwtModel } from "../model/token-model/token.model";
import jwtDecode from "jwt-decode";
import globalRouter from "../routes/global-router";
const baseUrl = process.env.REACT_APP_ENDPOINT;
const hideLoaderApi = [`${baseUrl}/team/searchUser`]
export const get = (url: string, opt?: any): Promise<Response> => {
  return axios.get(url, opt);
};

export const post = (url: string, data?: any, blob?: any): Promise<Response> => {
  return axios.post(url, data, blob);
};

export const deleteData = (url: string): Promise<Response> => {
  return axios.delete(url);
};

export const put = (url: string, data: any): Promise<Response> => {
  return axios.put(url, data);
};

let navigate: ((arg0: string) => void) | null = null;

export const setNavigate = (navigateFunction: any) => {
  navigate = navigateFunction
}



const loader = LoaderService()

const CancelToken = axios.CancelToken;
let source = CancelToken.source();
axios.interceptors.request.use(
  async (config) => {
    const apiEndpoint = config.url?.split('?')[0];

    if (apiEndpoint && !hideLoaderApi.includes(apiEndpoint)) {
      loader.next(true);
    }
    if (apiEndpoint !== `${baseUrl}/employee/generateToken`) {
      // if (token && token !== null) {
      //   user = jwtDecode(token);
      //   const { iat, exp } = user;
      //   const currentTimestamp = Math.floor(Date.now() / 1000);
      //   if (currentTimestamp >= iat && currentTimestamp <= exp) {
      //     config.headers["Authorization"] = "Bearer " + token;
      //   } else {
      //     if (globalRouter.navigate) {
      //       globalRouter.navigate('/')
      //       loader.next(false)
      //       localStorage.clear()
      //     }
      //   }
      // } else {
      //   if (globalRouter.navigate) {
      //     globalRouter.navigate('/')
      //     loader.next(false)
      //     localStorage.clear()
      //   }
      // }
      return tokenInterceptor(config)

    }
    return config

  },
  async (error) => {
    Promise.reject(error);
  }
);

const tokenInterceptor = (config: any) => {
  const token = localStorage.getItem("loginToken")
  console.log(token, "Token In interceptor")
  if (token) {
    let user: JwtModel;
    user = jwtDecode(token);
    const { iat, exp } = user;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (currentTimestamp >= iat && currentTimestamp <= exp) {
      config.headers.Authorization = `Bearer ${token}`;
      return config
    } else {
      handleExpireToken()
    }
  } else {
    handleNoToken()
  }
}

const handleExpireToken = () => {
  if (globalRouter.navigate) {
    globalRouter.navigate('/')
    loader.next(false)
    localStorage.clear()
  }
}
const handleNoToken = () => {
  if (globalRouter.navigate) {
    globalRouter.navigate('/')
    loader.next(false)
    localStorage.clear()
  }
}


axios.interceptors.response.use(response => {
  const status = response.status
  if (status && status !== null && (status === 200 || status === 204 || status === 201)) {
    loader.next(false)
  }
  return response;
}, error => {
  const status = error.response ? error.response.status : null
  if (status === 401 && globalRouter.navigate) {
    globalRouter.navigate("/");
    loader.next(false)
  } else if (status === null && globalRouter.navigate) {
    globalRouter.navigate("/");
    loader.next(false)
  } else if (status === 500 && globalRouter.navigate) {
    globalRouter.navigate("/");
    loader.next(false)
  } else if (error.message === 'Network Error') {
    console.log('Netword error')
  } else {
    loader.next(false)
  }
  // return Promise.reject(error);
  return null;
}
);
