import React, { useEffect, useState } from 'react';
import { AgGridReact } from "ag-grid-react";
import './employee.css'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useSelector } from 'react-redux';
import { Stack, Tooltip } from '@mui/material';
import EmployeeRegistration from '../../../componant/employee-registration/employee-registration';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import CustomCellRender from '../../custom-cell-render/custom-cell-render';
import CurrencyCellRenderer from '../../currency-cell-render/currency-cell-render';
import { currencyCode } from '../../../services/constant';
import EditEmployee from '../../../componant/edit-employee/edit-employee';
import { EmployeeModel } from '../../../model/employee-model/employee.model';
import FetchApi from '../../../fetch-api/fetch-api';
import { Grid } from '@material-ui/core';
import { height } from '@mui/system';
import ButtonWrapper from '../../../widgets/button/button';


const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const AdminEmployee = () => {
    const employeeState = useSelector((state: any) => state.allEmployee)
    const [rowData, setRowData] = useState<EmployeeModel[]>([]);
    const [renderEmployeeRegistration, setRenderEmployeeRegistration] = useState<boolean>(false);
    const [renderEditEmployee, setRenderEditEmployee] = useState<boolean>(false);
    const [editEmployee, setEditEmployee] = useState({});
    const [employeeName, setEmployeeName] = useState<string>("");
    const currency = currencyCode()

    const fetchApi = FetchApi()
    const fetchData = () => {
        fetchApi.fetchAllEmployee()
    }
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        if (employeeState) {
            setRowData(employeeState.employee)
        }
    }, [employeeState])

    const handleEditEmployees = (para: any) => {
        if (para !== undefined) {
            setRenderEditEmployee(true)
            setEditEmployee(para)
            setEmployeeName(para.name)

        }
    }


    const [columnDefs] = useState<any[]>([
        {
            headerName: "ID",
            field: "employee_id",
            resizable: true,
            filter: true,
            floatingFilter: true,
        },
        {
            headerName: "Name",
            field: "name",
            resizable: true,
            filter: true,
            floatingFilter: true,
        },
        {
            headerName: "Official Email",
            field: "officialEmail",
            resizable: true,
            filter: true,
            floatingFilter: true,
        },
        {
            headerName: "Personal Email",
            field: "personalEmail",

            resizable: true,
            filter: true,
            floatingFilter: true,
        },
        {
            headerName: "Designation",
            field: "designation",

            resizable: true,
            filter: true,
            floatingFilter: true,
        },
        {
            headerName: "Contact No",
            field: "contact_no",

            resizable: true,
            filter: true,
            floatingFilter: true,
        },
        {
            headerName: "Blood Group",
            field: "blood_group",

            resizable: true,
            filter: true,
            floatingFilter: true,
        },
        {
            headerName: "Gross Salary",
            field: "grossSalary",
            resizable: true,
            filter: true,
            floatingFilter: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        },
        {
            headerName: "Action",
            field: "",
            cellRenderer: (params: any) => (
                <CustomCellRender value={params} handleEditEmployees={handleEditEmployees} />


            ),
        },
    ]);

    const handleAddEmployee = () => {
        setRenderEmployeeRegistration(!renderEmployeeRegistration);
    }
    const handleBack = () => {
        setRenderEmployeeRegistration(false);
        setRenderEditEmployee(false);
    }

    const handleRenderEditEmployee = (flag: boolean) => {
        if (flag)
            setRenderEditEmployee(!flag)
    }


    return (
        <>
            <div className='main'>
                <Stack height='100%'>
                    <>
                        <div className='leave-heading-sub-container'>
                            <div className='heading4'>
                                {
                                    renderEmployeeRegistration || renderEditEmployee ?
                                        <Grid container className='employee-sub-heading-container'>
                                            <Grid item className='employee-sub-heading-arrow-item'><LightTooltip title="back" placement='right-start'>

                                                <ArrowBackIcon className='back-button' onClick={() => handleBack()} />

                                            </LightTooltip>
                                            </Grid>
                                            <Grid item className='employee-heading-sub-container'>
                                                {renderEmployeeRegistration ? <h2>Employee Registration</h2> : renderEditEmployee ? <h2 >Edit Employee</h2> : ""}
                                            </Grid>
                                        </Grid> :
                                        <Grid container className='employee-sub-heading-add-item'>
                                            <Grid item xs={11} >
                                                <h2>Employee List</h2>
                                            </Grid>
                                            <Grid item xs={1} style={{ textAlign: 'right' }}>
                                                <ButtonWrapper buttonType='circle' className='circular-add-button' onClick={handleAddEmployee} tooltipTitle='Add Employee' tooltipPlacement='left' />
                                            </Grid>
                                        </Grid>
                                }
                            </div>
                        </div>
                        <div className='employee-sub-container'>

                            {
                                renderEmployeeRegistration ? <EmployeeRegistration /> : renderEditEmployee ?
                                    <EditEmployee editableEmployee={editEmployee} renderEditEmployee={renderEditEmployee} handleRenderEditEmployee={handleRenderEditEmployee} employeeName={employeeName} />
                                    :
                                    <Grid item style={{ height: '100%' }}>
                                        <div className='employee-container'>

                                            <AgGridReact
                                                rowData={rowData}
                                                columnDefs={columnDefs}
                                                className="ag-theme-alpine"

                                            />

                                        </div>
                                    </Grid>
                            }
                        </div>
                    </>
                </Stack>

            </div >
        </>
    );
};

export default AdminEmployee



