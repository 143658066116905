import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, TextareaAutosize } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { createWorkFromHome, getNoOfLeaveDay } from '../../api-services/employee-services';
import { SnackbarService } from '../../services/behaviorSubject/behaviorSubject';
import ButtonWrapper from '../../widgets/button/button';

interface WorkFromHomeApplicationProps {
    onClose: () => void;
    handleEditEmployees?: (params: boolean) => void
}

interface fieldValueProps {
    fromDate: string,
    toDate: string,
    noOfDays: number,
    reasonForWFH: string
}

const WorkFromHomeApplication = (props: WorkFromHomeApplicationProps) => {
    const snackbarService = SnackbarService()
    const { control, handleSubmit, setValue, register } = useForm();
    const [fieldValue, setFieldValue] = useState<fieldValueProps>({
        fromDate: "",
        toDate: "",
        noOfDays: 0,
        reasonForWFH: ''
    })

    const today = dayjs()
    const maxDayOfStartDay = dayjs(fieldValue.toDate)
    const minDateOfToDate = dayjs(fieldValue.fromDate)

    function formatDateToCustomFormat(inputDate: any) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const customFormat = `${year}-${month}-${day}`;
        return customFormat;
    }

    useEffect(() => {
        if (fieldValue.fromDate !== "" && fieldValue.toDate !== "") {
            const start = new Date(fieldValue.fromDate);
            const end = new Date(fieldValue.toDate);
            console.log(start)
            start.setHours(0, 0, 0, 0);
            end.setHours(0, 0, 0, 0);
            getNoOfLeaveDay(fieldValue.fromDate, fieldValue.toDate).then((response: any) => {
                if (response?.data) {
                    setValue("noOfDays", response.data)
                    setFieldValue((d: any) => {
                        return { ...d, ["noOfDays"]: response.data }
                    })
                }
            })
        }
    }, [fieldValue.fromDate, fieldValue.toDate])

    const handleField = (e: any, field: any, type?: string) => {
        if (type === "date") {
            var value: String = formatDateToCustomFormat(e.$d.toLocaleDateString("en-US")).toString()
        } else {
            var value: String = e.target.value
        }

        if (value !== "DD-MM-YYYY") {
            setFieldValue((d: any) => {
                return { ...d, [field]: value }
            })
            setValue(field, value)


        } else {
        }
    }

    const onSubmit = (data: any) => {
        console.log(fieldValue)
        createWorkFromHome(fieldValue).then((response: any) => {
            if (response?.data.success) {
                snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
                setFieldValue({
                    fromDate: "",
                    toDate: "",
                    noOfDays: 0,
                    reasonForWFH: ''
                })
                props.onClose()
                if (props.handleEditEmployees)
                    props.handleEditEmployees(true)

            } else {
                snackbarService.next({ data: { open: true, severity: 'warning', message: response.data.message } })
            }
        })
    }
    console.log(fieldValue, "Field Value")
    return (
        <div style={{ height: '100%' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ height: '80%' }}>
                    <div style={{ height: '20%', padding: '5px', display: 'flex', justifyContent: 'space-between' }}>
                        <h4>Apply Work From Home</h4>
                        <CloseIcon
                            className='team-create-close-button'
                            onClick={props.onClose}
                        />
                    </div>
                    <div style={{ height: '80%', marginRight: '3%', marginTop: '4%' }}>
                        <FormControl required sx={{ ml: 1, mr: 0, minWidth: 120, display: "flex", flexDirection: "column", gap: "10px" }} fullWidth >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <DatePicker
                                    {...register("fromDate", {
                                        required: 'error message'
                                    })}
                                    label='Form Date'
                                    minDate={today}
                                    maxDate={maxDayOfStartDay}
                                    value={dayjs(fieldValue.fromDate)}
                                    format='DD/MM/YYYY'
                                    views={['year', 'month', 'day']}
                                    onChange={(e) => handleField(e, 'fromDate', 'date')}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <DatePicker
                                    {...register("toDate", {
                                        required: 'error message'
                                    })}
                                    label='To Date'
                                    disabled={fieldValue.fromDate !== '' ? false : true}
                                    format='DD/MM/YYYY'
                                    value={dayjs(fieldValue.toDate)}
                                    minDate={minDateOfToDate}
                                    onChange={(e) => handleField(e, 'toDate', 'date')}
                                    views={['year', 'month', 'day']}

                                />

                            </LocalizationProvider>
                        </FormControl>
                        <FormControl required sx={{ m: 1, minWidth: 120 }} fullWidth>

                            <Controller
                                name="noOfDays"
                                control={control}
                                render={({ field }) => (

                                    <TextField
                                        {...field}
                                        id="outlined-basic"
                                        variant="outlined"
                                        label="No of Days"
                                        disabled={true}
                                        value={fieldValue.noOfDays}
                                    />

                                )}
                            />
                        </FormControl>
                        <FormControl required sx={{ m: 1, minWidth: 120 }} fullWidth>
                            <Controller

                                name="reasonForLeave"
                                control={control}
                                render={({ field }) => (
                                    <TextareaAutosize
                                        {...field}
                                        {...register("reasonForLeave", {
                                            required: 'error message'
                                        })}
                                        value={fieldValue.reasonForWFH}
                                        aria-label="empty textarea"
                                        minRows={5}
                                        maxRows={5}
                                        maxLength={200}
                                        placeholder="Reason*(200 Character Max)"
                                        onChange={(e) => handleField(e, "reasonForWFH")}
                                    />
                                )}
                            />
                        </FormControl>
                    </div>
                </div>
                <div style={{ height: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ButtonWrapper title='Submit' type='submit' variant='contained' />
                </div>
            </form>
        </div>
    )
}

export default WorkFromHomeApplication