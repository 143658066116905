import currencyFormatter from 'currency-formatter';
import { useNavigate } from 'react-router';
export let prefixString = () => {
    const prefix = 'TTPL000'
    return prefix;
}

export let currencyCode = () => {
    const india = 'INR'
    return india
}

export const userleaveDetails = () => {
    let leaveDetails = [
        { leaveType: "Casual" },
        { leaveType: "Medical" },
        { leaveType: "Special" },
        { leaveType: "Vacation" },
    ]
    return leaveDetails
}

export const formateTimeZone = (time: Date) => {
    if (time !== null) {
        const timestamp: Date = new Date(time);
        var hours = timestamp.getHours().toString().padStart(2, '0');
        var minutes = timestamp.getMinutes().toString().padStart(2, '0');
        var seconds = timestamp.getSeconds().toString().padStart(2, '0');
        var newTimeFormate = hours + ":" + minutes + ":" + seconds
        return newTimeFormate
    }
}

export const formateDate = (timestamp: any) => {
    if (timestamp !== null) {
        const date = new Date(timestamp);

        // Get the year, month, and day
        const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits with leading zero if necessary
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two digits with leading zero if necessary
        const year = date.getFullYear();
        const newDate = ` ${day}/${month}/${year}`
        console.log(newDate, "Date")
        return newDate

    }
}



export const formatCurrency = (amount: number, currencyCode: string) => {
    return currencyFormatter.format(amount, { code: currencyCode });
}


export function searchByYearMonth(data: any, year: any, month: any) {
    console.log(data, "Data")
    const filteredData = data.filter((entry: any) => {
        const entryDate = entry.date.split('-');
        return parseInt(entryDate[1]) === parseInt(month) && parseInt(entryDate[2]) === parseInt(year);
    });
    filteredData.sort((a: any, b: any) => {
        const dateA = new Date(a.date.split('-').reverse().join('-'));
        const dateB = new Date(b.date.split('-').reverse().join('-'));

        return dateA.getTime() - dateB.getTime();
    });
    filteredData.filter((entry: any, index: number) => {
        entry.sno = index + 1;
    })
    return filteredData;

}

export const RedirectToHomePage = () => {
    const navigate = useNavigate();

    const navigation = () => {
        localStorage.clear()
        navigate("/");
    }
    return navigation;
}

