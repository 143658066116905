import React, { useState, useEffect } from 'react'
import './employee-registration.css'
import { Button } from '@mui/material'
import { detailsField } from '../../services/employeeFieldType';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { prefixString } from '../../services/constant';
import { useNavigate } from 'react-router';
import { postEmployee } from '../../api-services/employee-services';
import FetchApi from '../../fetch-api/fetch-api';
import { SnackbarService } from '../../services/behaviorSubject/behaviorSubject';
import { Grid } from '@material-ui/core';
import ButtonWrapper from '../../widgets/button/button';


interface EmployeeRegistrationProps {
    editableEmployee?: any;
    renderEditEmployee?: boolean;
}

interface FieldValueProps {
    name: string;
    contact_no: string;
    date_of_joining: string;
    grossSalary: string;
    officialEmail: string;
    roles: string
}
interface employeeDetailsOptionProps {
    value: string,
    label: string
}
interface employeeDetailsProps {
    name: string;
    type: string;
    label: string;
    options?: employeeDetailsOptionProps[]
}

const EmployeeRegistration = (props: EmployeeRegistrationProps) => {
    const snackbarService = SnackbarService()
    const prefix = prefixString()
    const [fieldValue, setFieldValue] = useState<FieldValueProps>({
        name: "",
        contact_no: "",
        date_of_joining: "",
        grossSalary: "",
        officialEmail: "",
        roles: ''
    })
    const { control, handleSubmit, setValue } = useForm();

    useEffect(() => {
        for (let key in props.editableEmployee) {
            setValue(key, props.editableEmployee[key])
        }

    }, [props.editableEmployee])

    const [equalAdressCheckboxStatus, setEqualAdressCheckboxStatus] = useState<boolean>(false);

    const field = detailsField()
    const newEmployeeDetails = field.newEmployeeDetails
    const myState = useSelector((state: any) => state.allEmployee)
    const fetchApi = FetchApi()

    const handleField = (e: any, name: any) => {

        setFieldValue((d: any) => {
            return { ...d, [name]: e.target.value }
        })
        setValue(name, e.target.value)
    }

    const onSubmit = (data: any) => {
        snackbarService.next({ data: { open: true, severity: 'success', message: 'Please wait...' } })
        data.contact_no = parseInt(data.contact_no)
        data.grossSalary = Number(data.grossSalary)
        if (!props.renderEditEmployee) {
            setEqualAdressCheckboxStatus(false)
            const id = myState.employee.map((ele: any, key: number) => {
                return parseInt(ele.employee_id.substring(4))

            })
            const sortedNumbers = id.sort((a: number, b: number) => a - b);
            data['employee_id'] = `${prefix}${sortedNumbers[sortedNumbers.length - 1] + 1}`
            postEmployee(data).then(function (response: any) {

                if (response?.data.success) {
                    fetchApi.fetchAllEmployee()
                    snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
                } else {
                    snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })

                }
            })

            fieldValue.contact_no = ""
            fieldValue.date_of_joining = ""
            fieldValue.grossSalary = ""
            fieldValue.name = ""
            fieldValue.officialEmail = ""
            fieldValue.roles = ""
            setValue("contact_no", "")
            setValue("date_of_joining", "")
            setValue("grossSalary", "")
            setValue("name", "")
            setValue("officialEmail", "")
        }
    }

    return (
        <div className='main'>
            <div style={{ height: 'calc(100% - 18px)' }}>
                <Grid container style={{ height: '100%', padding: '0px 40px 30px 40px' }}>

                    <div className='employee-registration-header' >
                        <h3>Employee Details</h3>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: 'calc(100% - 30px)' }}>
                        <div style={{ width: '500px', height: '100%' }}>

                            <Grid xs={12} container className='employee-registration-container'>
                                <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', height: '100%' }}>
                                    <Grid xs={12} item justifyContent='center' alignItems='center' style={{ height: 'calc(100% - 40px)' }}>
                                        <div className="form-container" >
                                            <div className='sub-from-container'>

                                                <div className='employee-registration-field-container'>
                                                    {
                                                        newEmployeeDetails.map((ele: employeeDetailsProps, key: number) => {
                                                            return (
                                                                <div key={key} className='field-sub-container'>
                                                                    {ele.type !== 'select' ? <>
                                                                        < label className='label'> {ele.label}</label>
                                                                        <Controller

                                                                            name={ele.name}
                                                                            control={control}
                                                                            render={({ field }) => (

                                                                                <>
                                                                                    <input
                                                                                        type={ele.type}
                                                                                        {...field}
                                                                                        value={fieldValue[ele.name as keyof FieldValueProps]}
                                                                                        onChange={(e) => handleField(e, ele.name)}
                                                                                        required
                                                                                    />

                                                                                </>
                                                                            )}
                                                                        />
                                                                    </> : <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <label className='label'>{ele.label}</label>
                                                                        <Controller
                                                                            name={ele.name}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <select
                                                                                    {...field}
                                                                                    onChange={(e) => handleField(e, ele.name)}
                                                                                    value={fieldValue.roles}
                                                                                    required
                                                                                >
                                                                                    <option value=''>Select...</option>

                                                                                    {ele.options !== undefined
                                                                                        ? ele.options.map((el: employeeDetailsOptionProps, ind: number) => (
                                                                                            <option key={ind} value={el.value}>{el.label}</option>
                                                                                        ))
                                                                                        : ''}
                                                                                </select>
                                                                            )}
                                                                        />


                                                                    </div>
                                                                    }
                                                                </div>
                                                            )

                                                        })
                                                    }

                                                </div >
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px' }} >
                                        <ButtonWrapper title={`${!props.renderEditEmployee ? "Submit" : "Update"}`} id='submit-button' variant='contained' type='submit' backgroundColor='var(--primaryColor)' />
                                    </Grid>
                                </form>
                            </Grid>
                        </div>
                    </div>

                </Grid >
            </div >
        </div >

    )
}

export default EmployeeRegistration