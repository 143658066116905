import React, { useEffect, useState } from 'react'
import './attendance-details.css'
import { AiFillCloseCircle, AiOutlinePlusSquare } from 'react-icons/ai';
import { formateTimeZone } from '../../../services/constant';


interface AttendanceDetailsProps {
    data: {
        clockInDate: string;
        clockIn: Date;
        clockOut: Date;
        workingHours: string,
        agenda: string,
        workUpdate: string
    }
    onClose: () => void;

}
const AttendanceDetails = (props: AttendanceDetailsProps) => {
    const [workDetails, setWorkDetails] = useState()
    console.log(props.data, "Data1")

    useEffect(() => {
        // setWorkDetails(props.data)
    }, [props.data])

    return (
        <>
            <div className='main'>
                <div className='attendance-details-main-container'>
                    <div className='attendance-details-close-button-container'>
                        <AiFillCloseCircle
                            className='attendance-details-close-button'
                            onClick={props.onClose}
                        />
                    </div>
                    <div style={{ display: "flex" }}>
                        <h4 className='attendance-details-header'>Work Details</h4>

                    </div>
                    <div className='attendance-details-sub-container'>
                        <div style={{ display: "flex" }}>
                            <p>Agenda:</p>
                            <p>{props.data.agenda}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <p>Work Update:</p>
                            <p>{props.data.workUpdate}</p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AttendanceDetails