import React, { useEffect, useState } from 'react'
import './lopAttendanceSummary.css'
import { AgGridReact } from 'ag-grid-react'
import { getEmpAttendanceSummaryWithLop } from '../../../../api-services/employee-services'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { detailsField } from '../../../../services/employeeFieldType';
import { searchByYearMonth } from '../../../../services/constant';

interface SelectedYearMonth {
    month: string,
    year: string,
    monthName: string
}

const UserLopAttendanceSummary = () => {
    const [rowData, setRowData] = useState<any[]>([])
    const [filterRowData, setFilterRowData] = useState<any[]>([])
    const detailsYearField = detailsField()
    const [selectedYearMonth, setSelectedYearMonth] = useState<SelectedYearMonth>({
        month: '',
        year: '',
        monthName: ''
    })

    const fetchData = () => {
        getEmpAttendanceSummaryWithLop().then((response: any) => {
            if (response.data.success) {
                console.log(response.data.payload)

                // Add serial numbers
                response.data.payload.lopDetails.forEach((item: any, index: number) => {
                    // item.sno = index + 1;
                    const date = new Date(item.date);
                    const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
                    item.date = formattedDate;
                });
                setRowData(response.data.payload.lopDetails)
            } else {
                setRowData([])
            }
        }).catch((err) => {

        });
    }

    useEffect(() => {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth() + 1
        setSelectedYearMonth((preav: any) => {
            return { ...preav, month: `${month}`, year: `${year}`, monthName: getMonthName(month) }
        })
    }, [])

    useEffect(() => {
        if (rowData.length > 0 && selectedYearMonth.year !== '' && selectedYearMonth.month !== '') {
            const filterData = searchByYearMonth(rowData, selectedYearMonth.year, selectedYearMonth.month)
            setFilterRowData(filterData)
        }
    }, [rowData, selectedYearMonth])

    const getMonthName = (monthNumber: any) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('en-US', { month: 'long' });
    }

    const onMenuChange = (e: SelectChangeEvent) => {
        const { name, value } = e.target
        setSelectedYearMonth((preav: any) => {
            return { ...preav, [name]: value.toString(), monthName: getMonthName(name === "month" ? value : selectedYearMonth.month) }
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const [columnDefs]: any[] = useState([
        {
            headerName: "S.No",
            field: "sno",
            width: 100,
        },
        {
            headerName: "Date",
            field: "date",
            width: 215,

        },
        {
            headerName: "Present",
            field: "present",
            width: 210,
        },
        {
            headerName: "Leave",
            field: "leave",
            width: 210,
        },
        {
            headerName: "Loss of Pay",
            field: "lossOfPay",
            width: 210,
        }
    ])
    return (
        <div className='main'>
            <div className='main-container'>
                <div className='user-lop-heading'>
                    <h2>Attendance Summary</h2>
                    <div className='user-lop-date-range-container'>
                        <FormControl fullWidth >
                            <InputLabel id="demo-simple-select-label">Year</InputLabel>
                            <Select
                                name='year'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Year"
                                value={selectedYearMonth.year}
                                onChange={onMenuChange}
                                sx={{ height: '30px' }}
                            >{
                                    detailsYearField.salarySlipYear.map((item: any, index: number) => {
                                        return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                                    })
                                }

                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Month</InputLabel>
                            <Select
                                name='month'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Month"
                                value={selectedYearMonth.month}
                                onChange={onMenuChange}
                                sx={{ height: '30px' }}
                            >
                                {
                                    detailsYearField.salarySlipMonth.map((item: any, index: number) => {
                                        return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='user-lop-sub-container'>
                    <AgGridReact
                        rowData={filterRowData}
                        columnDefs={columnDefs}
                        className="ag-theme-alpine"
                    />
                </div>
            </div>

        </div>
    )
}

export default UserLopAttendanceSummary