import { useEffect, useState } from 'react'
import { Box, Modal } from '@mui/material';
import { useSelector } from 'react-redux';
import './holiday.css'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomCellRender from '../../custom-cell-render/custom-cell-render';
import FetchApi from '../../../fetch-api/fetch-api';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CreateHoliday from '../../../componant/createHoliday/createHoliday';
import ModalWrapper from '../../../widgets/modal/modal';
import ButtonWrapper from '../../../widgets/button/button';


const style = {

  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 0.9,
    sm: 0.8,
    md: 400
  },
  bgcolor: 'white',
  boxShadow: 24,
  p: 2,
  borderRadius: 2

};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    border: '1px solid rgb(39,112,222)',
    borderRadious: "2px",
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    // boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

interface rawDataProps {
  date: string,
  id: number,
  reason: string
}
const Holiday = () => {
  const [openModal, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true)
  };
  const handleClose = () => setOpen(false);
  const fetchApi = FetchApi()
  const [rowData, setRowData] = useState<rawDataProps[]>([])
  const state = useSelector((state: any) => state.allEmployee)

  useEffect(() => {
    if (state.holiday.length > 0) {
      setRowData(state.holiday)
    } else {
      setRowData([])
    }
  }, [state.holiday])

  useEffect(() => {
    fetchApi.fetchHoliday()
  }, [])


  console.log(rowData, "Row Data")

  const [columnDefs]: any[] = useState([
    {
      headerName: "Sl.",
      field: "sno",
      flex: 1,
      resizable: true,
    },
    {
      headerName: "Date",
      field: "date",
      flex: 1,
      width: 200,
    },
    {
      headerName: "Reason",
      field: "reason",
      width: 400,
      flex: 1,
      headerClass: 'text-center',
    },
    {
      headerName: "Action",
      field: "",
      flex: 1,
      width: 301,
      headerClass: 'text-center',
      cellRenderer: (params: any) => (
        <CustomCellRender value={params} actionStatus={true} />
      ),
    }
  ])

  const handleAddHoliday = () => {
    handleOpen()
  }

  const handleAllHoliday = (params: boolean) => {
    if (params)
      fetchApi.fetchHoliday()
  }

  return (
    <div className='main'>
      <div className='main-container'>
        <div className='admin-holiday-heading'>
          <h2>Holiday</h2>
          <ButtonWrapper buttonType='circle' onClick={handleAddHoliday} className='circular-add-button' tooltipTitle='Create Holiday' tooltipPlacement='left' />
        </div>
        <ModalWrapper open={openModal} close={handleClose} children={<CreateHoliday onClose={handleClose} handleAllHoliday={handleAllHoliday} />} />
        <div className='admin-holiday-sub-container'>
          <div style={{ height: '100%' }}>
            <div className='admin-holiday-list-container'>
              <div className='holiday-sub-heading-container'>
                <h4>Holiday List</h4>
              </div>
              <div className="holiday-table-wrapper">
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  className="ag-theme-alpine"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Holiday