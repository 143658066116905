import React, { useEffect, useState } from 'react'
import './teamManagement.css'
import { AgGridReact } from "ag-grid-react";
import CustomTeamDetailsCell from '../../custom-team-details-cell-render/customTeamDetailsCell';
import EditTeamDetails from '../../edit-team-details-cell/editTeamDetails';
import { getSingleEmployeeTeamDetails } from '../../../api-services/employee-services';
import FetchApi from '../../../fetch-api/fetch-api';
import { useSelector } from 'react-redux';
import { Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AddTeamProject from '../../../componant/addTeamProject/addTeamProject';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { Tooltip } from '@mui/material';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import jwt from 'jwt-decode';
import { JwtModel } from '../../../model/token-model/token.model';
import { SnackbarService } from '../../../services/behaviorSubject/behaviorSubject';
import { modalStyle } from '../../../style/style';
import ModalWrapper from '../../../widgets/modal/modal';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));



const UserTeamManagement = () => {
    const style = modalStyle()
    const snackbarService = SnackbarService()
    const state = useSelector((state: any) => state.team)
    const [rawData, setRawData] = useState([])
    const [actionStatus, setActionStatus] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState(null)



    const token = localStorage.getItem("loginToken") || ''
    let user: JwtModel = jwt(token);

    const handleClose = () => {
        setOpenModal(false)
        setSelectedRow(null)
    };
    const fetchApi = FetchApi()
    useEffect(() => {
        if (user.roles === "COREMEMBERS") {
            fetchApi.fetchAdminTeam()
        } else {
            fetchApi.fetchUserTeam()
        }
    }, [])

    useEffect(() => {
        if (user.roles === "COREMEMBERS" && state.admin_team !== null) {
            setRawData(state.admin_team)
        } else if (user.roles === "USER" && state.user_team !== null) {
            setRawData(state.user_team)
        } else {
            setRawData([])
        }
    }, [state])

    const [columnDefs, setColumnDefs] = useState<any[]>(
        [
            {
                headerName: "S.No",
                field: "sno",
                width: 100,
            },
            {
                headerName: "Project Name",
                field: "projectName",
                width: 200,
            },
            {
                headerName: "Client",
                field: "projectClient",
                width: 200,
            },
            {
                headerName: "Details",
                field: "projectDetails",
                width: 200,
            },
            {
                headerName: "Start Date",
                field: "projectStartDate",
                width: 200,
            },
            {
                headerName: "End Date",
                field: "projectDeliveryDate",
                width: 200,
            },
            {
                headerName: "Demo Date",
                field: "projectDemoDate",
                width: 200,
            },
            {
                headerName: "Project Leader",
                field: "projectLed",
                width: 200,

            },
            {
                headerName: "Fontend Member",
                field: "projectFrontEndMembers",
                width: 200,
                cellRenderer: (params: any) => (
                    <CustomTeamDetailsCell value={params} />
                )

            },
            {
                headerName: "Backend Member",
                field: "projectBackEndMembers",
                width: 200,
                cellRenderer: (params: any) => (
                    <CustomTeamDetailsCell value={params} />
                )

            },
            {
                headerName: "Status",
                field: "projectWorkingStatus",
                width: 200,
            },
            {
                headerName: "Remarks",
                field: "projectRemark",
                width: 200,
            }
        ]
    )

    useEffect(() => {
        if (user.roles === "COREMEMBERS") {
            const actionfield = {
                headerName: "Action",
                field: "",
                width: 200,
                cellRenderer: (params: any) => (
                    <EditTeamDetails value={params} onActionTrigger={onActionClick} />
                )

            }
            setColumnDefs((prevColumnDefs) => [...prevColumnDefs, actionfield])
        }


    }, [])




    const onActionClick = (opt: any) => {
        switch (opt.action) {
            case 'edit':
                setSelectedRow(opt.data)
                setOpenModal(true)
                setActionStatus(true)
                break;
        }
    }



    const onCreateTeam = () => {
        setOpenModal(true)
        setActionStatus(false)
    }

    const handleModalClose = (flag: boolean) => {
        setOpenModal(flag)
    }

    return (
        <div className='main'>
            <div className='main-container'>
                <div className='heading4'>
                    <h2>Team Management</h2>
                    <div>
                        {user.roles !== 'USER' ? <LightTooltip title="Create Team" placement='right'>< AddCircleIcon
                            className='circular-add-button'
                            sx={{ fontSize: "200%" }}
                            onClick={onCreateTeam}
                        />
                        </LightTooltip> : ''}
                        <ModalWrapper open={openModal} close={handleClose} children={<AddTeamProject onClose={handleClose} actionStatus={actionStatus} data={selectedRow} handleModalClose={handleModalClose} />} />
                    </div>
                </div>
                <div className='holiday-sub-container'>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={rawData}
                        editType={'fullRow'}
                        className="ag-theme-alpine"
                        suppressRowClickSelection={true}
                        enableRangeSelection={true}
                        stopEditingWhenCellsLoseFocus={true}
                        singleClickEdit={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserTeamManagement
