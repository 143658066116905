export const ActionTypes = {
  SET_EMPLOYEE: "SET_EMPLOYEE",
  ADD_EMPLOYEE: "ADD_EMPLOYEE",
  UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",
  CLOCK_IN: "CLOCK_IN",
  CLOCK_OUT: "CLOCK_OUT",
  SET_LEAVE: "SET_LEAVE",
  SET_ATTENDANCE: "SET_ATTENDANCE",
  SET_HOLIDAY: "SET_HOLIDAY",
  UPDATE_HOLIDAY: "UPDATE_HOLIDAY",
  SET_SALARY: "SET_SALARY",
  SET_LEAVE_APPLICATION: "SET_LEAVE_APPLICATION",
  SET_ADMIN_TEAM: "SET_ADMIN_TEAM",
  SET_USER_TEAM: "SET_USER_TEAM"
};
