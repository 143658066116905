import React, { useEffect, useState } from 'react'
import './reset-password.css'
import CancelIcon from '@mui/icons-material/Cancel';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { resetPassword } from '../../api-services/employee-services';
import { JwtModel } from '../../model/token-model/token.model';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { SnackbarService } from '../../services/behaviorSubject/behaviorSubject';
import ButtonWrapper from '../../widgets/button/button';




interface ResetPasswordProps {
    onClose: () => void,
    user: JwtModel
}
interface FieldValueProps {
    username: string,
    oldpassword: string,
    newpassword: string,
}
const ResetPassword = (props: ResetPasswordProps) => {
    const snackbarService = SnackbarService()
    const [fieldValue, setFieldValue] = useState<FieldValueProps>({
        username: '',
        oldpassword: '',
        newpassword: '',
    })
    const [fieldDisable, setFieldDisable] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>({});
    const { control, handleSubmit, setValue, getValues, reset } = useForm();
    const resetPasswordField = [
        { name: 'username', type: 'text', label: 'Username' },
        { name: 'oldpassword', type: 'text', label: 'Old Password' },
        { name: 'newpassword', type: 'text', label: 'New Password' },
    ]
    useEffect(() => {
        if (props.user) {
            setFieldValue((preav) => {
                return { ...preav, username: props.user.sub }
            })
            setValue('username', props.user.sub)
            setFieldDisable(true)
        }
    }, [props.user, setValue])
    const handleField = (e: any) => {
        const { value, name } = e.target
        setFieldValue((preav) => {
            return { ...preav, [name]: value }
        })
        setValue(name, value)
    }
    const onSubmit = (data: any) => {
        resetPassword(data.username, data.oldpassword, data.newpassword).then((response: any) => {
            if (response.data.success) {


                setFieldValue((preav) => {
                    return {
                        ...preav,
                        username: '',
                        oldpassword: '',
                        newpassword: '',
                    }
                })
                resetPasswordField.map((item: any) => {
                    setValue(item.name, '')
                })
                snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
                setTimeout(() => {
                    props.onClose()
                }, 1000)

            } else {
                snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
            }
        })
    }


    const togglePasswordVisibility = (e: any, field: any) => {
        console.log(field)
        // if (field === 'oldpassword') {
        //     setShowPassword((prevShowPassword) => !prevShowPassword);
        // } else {
        //     console.log('newpassword')
        // }
        setShowPassword(prevVisibility => ({
            ...prevVisibility,
            [field]: !prevVisibility[field]
        }));

    };

    console.log(showPassword, "Show passord value")

    return (
        <div className='reset-password-main-container'>

            <h4>Change Password</h4>
            <div style={{ position: 'absolute', right: '5px', top: '5px' }}>
                <CancelIcon
                    className='close-button'
                    onClick={() => { props.onClose() }}
                />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                {
                    resetPasswordField.map((item: any, index: number) => {
                        return <div key={index}>
                            <label>{item.label}</label>
                            <Controller
                                name={item.name}
                                control={control}
                                defaultValue=""

                                render={({ field, fieldState }) => (
                                    <div style={{ display: 'flex' }}>
                                        <input
                                            type={item.name === 'username' ? item.type : showPassword[item.name] ? item.type : 'password'}
                                            {...field}
                                            onChange={handleField}
                                            disabled={item.name !== undefined && item.name === 'username' ? fieldDisable : false}
                                            required
                                            value={fieldValue[item.name as keyof FieldValueProps]}
                                        />

                                        {
                                            item.name !== 'username' ? <>
                                                {showPassword[item.name] ? <FaEyeSlash
                                                    size={23}
                                                    onClick={(e) => togglePasswordVisibility(e, item.name)}
                                                    className={item.name === 'oldpassword' ? 'reset-toggle-password-button' : 'reset-toggle-password-button1'}
                                                /> : <FaEye
                                                    size={23}
                                                    onClick={(e) => togglePasswordVisibility(e, item.name)}
                                                    className={item.name === 'oldpassword' ? 'reset-toggle-password-button' : 'reset-toggle-password-button1'}
                                                />
                                                }
                                            </> : ''
                                        }




                                    </div>
                                )}
                            />

                        </div>
                    })
                }
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px' }}>
                    <ButtonWrapper id='submit-btn' title='Submit' type='submit' variant='contained' />
                </div>
            </form>


        </div>
    )
}

export default ResetPassword