import React, { useEffect } from 'react'
import { Routes, Route } from "react-router-dom"
import User from '../container/user/user'
import { Login } from '../container/login/login'
import AdminLayout from '../container/admin/admin'
import AdminDashboard from '../pages/admin/dashboard/dashboard'
import AdminEmployee from '../pages/admin/employee/employee'
import AdminAttendance from '../pages/admin/attendance/attendance'
import AdminLeave from '../pages/admin/leave/leave'
import AdminPayroll from '../pages/admin/payroll/payroll'
import UserAttendance from '../pages/user/attendance/attendance'
import UserLeave from '../pages/user/leave/leave'
import UserPayroll from '../pages/user/payroll/payroll'
import UserDashboard from '../pages/user/dashboard/dashboard'
import Holiday from '../pages/admin/holiday/holiday'
import UserHoliday from '../pages/user/holiday/holiday'
import UserAccount from '../pages/user/account/account'
import UserLeaveReview from '../pages/user/leave/leave-review/leave-review'
import LeaveReview from '../pages/admin/leave/leave-review/LeaveReview'
import { SalarySlip } from '../pages/user/payroll/salarySlip/salarySlip'
import AdminsalarySlip from '../pages/admin/payroll/salarySlip/salarySlip'
import AdminAccount from '../pages/admin/account/account'
import TeamManagement from '../pages/admin/teamManagement/teamManagement'
import UserTeamManagement from '../pages/user/teamManagement/teamManagement'
import UserWorkFromHome from '../pages/user/leave/work-from-home/workFromHome'
import WorkFromHome from '../pages/admin/leave/work-from-home/workFromHome'
import LopAttendanceLeaveSummary from '../pages/admin/attendance/lopAttendanceLeaveSummary.tsx/lopAttendanceLeaveSummary'
import UserLopAttendanceSummary from '../pages/user/attendance/lopAttendanceSummary/lopAttendanceSummary'

const AllRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/admin/*" element={<AdminLayout />} >
                    <Route path='dashboard' element={<AdminDashboard />} />
                    <Route path='employee' element={<AdminEmployee />} />
                    <Route path='attendance' element={<AdminAttendance />} />
                    <Route path='lopAttendanceLeaveSummary' element={<LopAttendanceLeaveSummary />} />
                    <Route path='leave' element={<AdminLeave />} />
                    <Route path='AdminAccount' element={<AdminAccount />} />
                    <Route path='leave-review' element={<LeaveReview />} />
                    <Route path='work-from-home' element={<WorkFromHome />} />
                    <Route path='payroll' element={<AdminPayroll />} />
                    <Route path='salarySlip' element={<AdminsalarySlip />} />
                    <Route path='holiday' element={<Holiday />} />
                    <Route path='teamManagement' element={<TeamManagement />} />
                </Route>
                <Route path="/user/*" element={<User />} >
                    <Route path='dashboard' element={<UserDashboard />} />
                    <Route path='attendance' element={<UserAttendance />} />
                    <Route path='userLopAttendanceLeaveSummary' element={<UserLopAttendanceSummary />} />
                    <Route path='UserAccount' element={<UserAccount />} />
                    <Route path='leave' element={<UserLeave />} />
                    <Route path='leave-review' element={<UserLeaveReview />} />
                    <Route path='work-from-home' element={<UserWorkFromHome />} />
                    <Route path='payroll' element={<UserPayroll />} />
                    <Route path='salarySlip' element={<SalarySlip />} />
                    <Route path='holiday' element={<UserHoliday />} />
                    <Route path='teamManagement' element={<UserTeamManagement />} />
                </Route>
            </Routes>
        </>
    )
}

export default AllRoutes