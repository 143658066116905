import { Button } from '@mui/material'
import React, { useState } from 'react'
import './customTeamDetailsCell.css'
import Modal from '@mui/material/Modal'
import { Box } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import TeamMembersDetails from '../../componant/teamMembersDetails/teamMembersDetails'
import ModalWrapper from '../../widgets/modal/modal'

const CustomTeamDetailsCell = (props: { value: any, actionStatus?: boolean }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const onView = () => {
    handleModalOpen()
  }
  return (

    <>
      <div>
        <div className='team-details-button-container'>
          <Button onClick={onView} >View</Button>
        </div>
        <ModalWrapper open={modalOpen} close={handleModalClose} children={<TeamMembersDetails onClose={handleModalClose} members={props} actionStatus={props.actionStatus ? props.actionStatus : false} />} />

      </div>
    </>
  )
}

export default CustomTeamDetailsCell