import React, { useEffect, useState } from 'react'
import './editHoliday.css'
import CancelIcon from '@mui/icons-material/Cancel';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { updateHoliday } from '../../api-services/employee-services';
import FetchApi from '../../fetch-api/fetch-api';
import { SnackbarService } from '../../services/behaviorSubject/behaviorSubject';




interface EditEmployeeProps {
  onClose: () => void,
  data: any
}
interface FieldValueProps {
  date: string,
  reason: string,
}
const EditHoliday = (props: EditEmployeeProps) => {
  const snackbarService = SnackbarService()
  const holidayField = [
    { name: 'date', type: 'date', label: 'Holiday Date' },
    { name: 'reason', type: 'text', label: 'Reason For Holiday' }
  ]
  const { control, handleSubmit, setValue } = useForm<FieldValueProps>();
  const [fieldValue, setFieldValue] = useState<FieldValueProps>({
    date: '',
    reason: ''
  })
  const formateDate = (date: any) => {
    const currentDate = date.split('/', 3)
    const FormateDate = `${currentDate[2]}-${currentDate[1]}-${currentDate[0]}`

    return FormateDate
  }
  const fetchApi = FetchApi()
  useEffect(() => {
    if (props) {
      const currentFormatedDate = formateDate(props.data.data.date)
      setFieldValue((prev: any) => {
        return { ...prev, date: currentFormatedDate, reason: props.data.data.reason }
      })
      setValue('date', currentFormatedDate)
      setValue('reason', props.data.data.reason)
    }
  }, [])

  const handleField = (e: any) => {
    const { value, name } = e.target
    setFieldValue((prev: any) => {
      return { ...prev, [name]: value }
    })
    setValue(name, value)
  }
  const onSubmit = (data: any) => {
    const id = props.data.data.id
    updateHoliday(id, data).then((response: any) => {
      if (response?.data.success) {
        fetchApi.fetchHoliday()
        snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
      }
    })
    setFieldValue((prev: any) => {
      return {
        ...prev,
        date: '',
        reason: ''
      }
    })
  }
  return (
    <>
      <div className='main'>
        <div className='edit-holiday-sub-container'>
          <div style={{ position: 'absolute' }}>
            <h4>Edit Holiday</h4>
          </div>
          <div style={{ position: 'relative', textAlign: 'right', top: "-10px" }}>
            <CancelIcon
              className='close-button'
              onClick={() => { props.onClose() }}
            />
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {
                holidayField.map((item: any, index: number) => {
                  return <div style={{ marginTop: '2px' }} key={index}>
                    <Controller
                      name={item.name}
                      control={control}
                      defaultValue=""
                      render={({ field }) => (

                        <input
                          type={item.type}
                          {...field}
                          onChange={handleField}
                          value={fieldValue[item.name as keyof FieldValueProps]}
                        />

                      )}
                    />
                  </div>
                })

              }
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                <Button
                  id='submit-btn'
                  variant='contained'
                  type='submit'
                >Submit</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditHoliday