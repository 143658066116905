import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';
import './leave-review.css';
import { useSelector } from 'react-redux';
import ApproveRejectCell from '../../../custom-cell-render/approve-reject-cell';
import { useParams } from 'react-router';
import { downloadLeaveAttachment, getAllLeaveOfEmp } from '../../../../api-services/employee-services';

export default function LeaveReview() {
    const [rowData, setRowData] = useState([]);
    var fileNames = ''
    const fetchData = () => {
        getAllLeaveOfEmp().then((response: any) => {
            if (response?.data.success)
                setRowData(response.data.payload)
        }).catch()
    }
    useEffect(() => {
        fetchData()
    }, []);

    const onDownloadAttachment = (e: any, id: any) => {
        downloadLeaveAttachment(id).then((response: any) => {
            if (response?.data) {
                const linkSource = window.URL.createObjectURL(response.data);
                const downloadLink = document.createElement("a");
                const fileName = fileNames;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        })
    }
    const handleEditEmployees = (params: boolean) => {
        if (params) {
            fetchData()
        }
    }

    return (
        <div className='main'>
            <div className='main-container'>
                <div className='leave-review-heading'>
                    <h2>Leave Review</h2>
                </div>
                <div className='leave-review-container' style={{ paddingRight: '1%' }}>
                    {rowData.length > 0 ? (
                        rowData.map((el: any, i) => {
                            const parts = el.attachment.split('_');
                            const filename = parts[parts.length - 1].trim();
                            fileNames = filename
                            return <React.Fragment key={i}>

                                <Card sx={{ width: "100%", marginBottom: '15px' }} className='shadow' key={i}>
                                    <CardContent>
                                        <div className="card-body pt-0" style={{ width: '100%' }}>

                                            <div
                                                className='leave-review-data-container table table-bordered'
                                            >
                                                <div className='leave-review-left-data-container'>
                                                    <div className='leave-review-sub-container'>
                                                        <div className='leave-review-field-container'
                                                        >
                                                            Name
                                                        </div>
                                                        <div className='leave-review-field-container1'>:</div>
                                                        <div className='leave-review-field-container2'>
                                                            {el.name}
                                                        </div>
                                                    </div>

                                                    <div className='leave-review-sub-container'>
                                                        <div
                                                            className='leave-review-field-container'
                                                        >
                                                            Leave Type
                                                        </div>
                                                        <div className='leave-review-field-container1'>:</div>
                                                        <div className='leave-review-field-container2'>
                                                            {el.leaveType}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='leave-review-right-data-container'>
                                                    {
                                                        el.session === 'Full Day' ? <>
                                                            <div className='leave-review-sub-container'>
                                                                <div
                                                                    className='leave-review-field-container3'
                                                                >
                                                                    From Date
                                                                </div>
                                                                <div className='leave-review-field-container1'>:</div>
                                                                <div className='leave-review-field-container4'>
                                                                    {el.fromDate
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='leave-review-sub-container'>
                                                                <div
                                                                    className='leave-review-field-container3'
                                                                >
                                                                    To Date
                                                                </div>
                                                                <div className="leave-review-field-container1">:</div>
                                                                <div className='leave-review-field-container4'>
                                                                    {el.toDate
                                                                    }
                                                                </div>
                                                            </div>
                                                        </> : <>
                                                            <div className='leave-review-sub-container'>
                                                                <div
                                                                    className='leave-review-field-container3'
                                                                >
                                                                    Working Date
                                                                </div>
                                                                <div className='leave-review-field-container1'>:</div>
                                                                <div className='leave-review-field-container4'>
                                                                    {el.fromDate
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    <div className='leave-review-sub-container'>
                                                        <div
                                                            className='leave-review-field-container3'
                                                        >
                                                            Session
                                                        </div>
                                                        <div className='leave-review-field-container1'>:</div>
                                                        <div className='leave-review-field-container4'>
                                                            {el.session}
                                                        </div>
                                                    </div>
                                                    {el.session === 'Half Day' ? <div className='leave-review-sub-container'>
                                                        <div
                                                            className='leave-review-field-container3'
                                                        >
                                                            Schedule
                                                        </div>
                                                        <div className='leave-review-field-container1'>:</div>
                                                        <div className='leave-review-field-container4'>
                                                            {el.leavePeriod}
                                                        </div>
                                                    </div> : ''}
                                                    <div className='leave-review-sub-container'>
                                                        <div
                                                            className='leave-review-field-container3'
                                                        >
                                                            No of Days
                                                        </div>
                                                        <div className='leave-review-field-container1'>:</div>
                                                        <div className='leave-review-field-container4'>
                                                            {el.noOfDays}
                                                        </div>
                                                    </div>
                                                    <div className='leave-review-sub-container'>
                                                        <div
                                                            className='leave-review-field-container3'
                                                        >
                                                            Attachment
                                                        </div>
                                                        <div className='leave-review-field-container1'>:</div>
                                                        <div className='leave-review-field-container5'>
                                                            <div style={{ width: '100%' }}>
                                                                {el.attachment !== 'NA' ?
                                                                    <div className='leave-review-field-container-file'>
                                                                        <div style={{ width: "75%" }}>{filename}</div>
                                                                        <div className='width:"25%"'>
                                                                            <Button
                                                                                variant='contained'
                                                                                size='small'
                                                                                style={{
                                                                                    background: "var(--primaryColor)", color: "white"
                                                                                }}
                                                                                className='leave-review-download-button-container'
                                                                                onClick={(e) => onDownloadAttachment(e, el.leaveId)}
                                                                            ><DownloadIcon /></Button></div>
                                                                    </div> : <div className='leave-review-field-container-no-file'>
                                                                        {el.attachment}
                                                                    </div>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='leave-review-sub-container'>
                                                        <div
                                                            className='leave-review-field-container3'
                                                        >
                                                            Reason for Leave
                                                        </div>
                                                        <div className='leave-review-field-container1'>:</div>
                                                        <div className='leave-review-field-container4'>
                                                            {el.reasonForLeave}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </CardContent>
                                    <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '18px' }}>
                                        <ApproveRejectCell value={el} handleEditEmployees={handleEditEmployees} />
                                    </CardActions>
                                </Card>
                            </React.Fragment>
                        })
                    ) : (
                        <h3>No Data Found</h3>
                    )}
                </div>
            </div>
        </div>
    );
}
