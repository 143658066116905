import jwt from 'jwt-decode';
import { JwtModel } from '../model/token-model/token.model';


export const checkLoginType = () => {
    const token = localStorage.getItem('loginToken') || '';
    const user: JwtModel = jwt(token);
    localStorage.setItem('userName', user.name)
    return user?.roles === 'ADMIN';
}


