import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './userAttendance.css'
import { Button, Modal, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import AttendanceDetails from '../attendance-details/attendance-details'
import { getAttendance } from '../../../api-services/employee-services'
import jwt from 'jwt-decode';
import { JwtModel } from '../../../model/token-model/token.model'
import { formateTimeZone, searchByYearMonth } from '../../../services/constant'
import { modalStyle } from '../../../style/style'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { detailsField } from '../../../services/employeeFieldType'
import { AgGridReact } from 'ag-grid-react'
import CustomDetailsCellRender from '../../custom-details-cell-render/custom-details-cell-render'

interface ClockSateProps {
    clockIn: string,
    clockOut: string,
    clockInDate: string,
    workingHours: string,
    employee_id: string
}

interface SelectedYearMonth {
    month: string,
    year: string,
    monthName: string
}

const UserAttendance = () => {
    const detailsYearField = detailsField()
    const [selectedYearMonth, setSelectedYearMonth] = useState<SelectedYearMonth>({
        month: '',
        year: '',
        monthName: ''
    })
    const [clockState, setClockState] = useState<ClockSateProps[]>([])
    const [filterRowData, setFilterRowData] = useState<any[]>([])


    console.log(filterRowData, "Filter Data")

    useEffect(() => {
        getAttendance().then((response: any) => {
            if (response?.data.success) {
                response.data.payload.forEach((item: any, index: number) => {
                    const date = item?.clockInDate.split('/');
                    const formatedDate = `${date[0]}-${date[1]}-${date[2]}`
                    item.date = formatedDate;
                    delete item.clockInDate;
                });

            }
            if (response !== null) {
                setClockState(response.data?.payload)
            } else {
                setClockState([])
            }
        })
    }, [])

    console.log(clockState, "Clock State")

    useEffect(() => {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth() + 1
        setSelectedYearMonth((preav: any) => {
            return { ...preav, month: `${month}`, year: `${year}`, monthName: getMonthName(month) }
        })
    }, [])

    useEffect(() => {
        if (clockState.length > 0 && selectedYearMonth.year !== '' && selectedYearMonth.month !== '') {
            const filterData = searchByYearMonth(clockState, selectedYearMonth.year, selectedYearMonth.month)
            setFilterRowData(filterData)
        }
    }, [clockState, selectedYearMonth])

    console.log(filterRowData, "Filter Data")
    console.log(clockState, "Clock State")

    const getMonthName = (monthNumber: any) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('en-US', { month: 'long' });
    }

    const onMenuChange = (e: SelectChangeEvent) => {
        const { name, value } = e.target
        setSelectedYearMonth((preav: any) => {
            return { ...preav, [name]: value.toString(), monthName: getMonthName(name === "month" ? value : selectedYearMonth.month) }
        })
    }

    const [columnDefs]: any[] = useState([
        {
            headerName: "S.No",
            field: "sno",
            width: 100,
        },
        {
            headerName: "Date",
            field: "date",
            width: 130,
        },
        {
            headerName: "Clock In",
            field: "clockIn",
            width: 100,
            valueFormatter: (params: any) => formateTimeZone(params.value),
        },
        {
            headerName: "Clock Out",
            field: "clockOut",
            width: 100,
            valueFormatter: (params: any) => formateTimeZone(params.value),
        },
        {
            headerName: "Total Hours",
            field: "loginHours",
            width: 135,
        },
        {
            headerName: "Break Hour",
            field: "breakHours",
            width: 135,
        },
        {
            headerName: "Working Hour",
            field: "workingHours",
            width: 130,
        },
        {
            headerName: "Action",
            field: "",
            width: 100,
            cellRenderer: (params: any) => (<CustomDetailsCellRender data={params} />)
        }
    ])

    return (
        <>
            <div className='main'>
                <div className="main-container">
                    <Stack height='100%'>
                        <div className='attendance-list-heading'>
                            <h2>Attendance List</h2>
                            <div className='user-lop-date-range-container'>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                    <Select
                                        name='year'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Year"
                                        value={selectedYearMonth.year}
                                        onChange={onMenuChange}
                                        sx={{ height: '30px' }}
                                    >{
                                            detailsYearField.salarySlipYear.map((item: any, index: number) => {
                                                return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                    <Select
                                        name='month'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Month"
                                        value={selectedYearMonth.month}
                                        onChange={onMenuChange}
                                        sx={{ height: '30px' }}
                                    >
                                        {
                                            detailsYearField.salarySlipMonth.map((item: any, index: number) => {
                                                return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='user-attendance-table-container'>
                            <AgGridReact
                                rowData={filterRowData}
                                columnDefs={columnDefs}
                                className="ag-theme-alpine"
                            />
                        </div>
                    </Stack>
                </div >

            </div >
        </>
    )
}

export default UserAttendance