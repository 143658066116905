import * as React from 'react';
import './layout.css'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import BrowseGalleryOutlinedIcon from '@mui/icons-material/BrowseGalleryOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import { BiSolidDashboard } from "react-icons/bi";
import { BsPeopleFill } from "react-icons/bs";
import { FcLeave } from 'react-icons/fc'
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { MdOutlineMapsHomeWork } from "react-icons/md";
import { SidenavModel } from '../../model/side-nav-model/side-nav.model';
import { useNavigate } from 'react-router';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import { Avatar } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { checkLoginType } from '../../services/loginType';
import { JwtModel } from '../../model/token-model/token.model';
import jwt from 'jwt-decode';
import { RedirectToHomePage } from '../../services/constant';
import globalRouter from '../../routes/global-router';

const drawerWidth = 250;

const adminRoutes: SidenavModel[] = [
    {
        path: "dashboard",
        name: "Dashboard",
        icon: <BiSolidDashboard size={22} color="var(--primaryColor)" />
    },
    {
        path: "employee",
        name: "Employee",
        icon: <BsPeopleFill size={22} color="var(--primaryColor)" />,
    },
    {
        path: "attendance",
        name: "Attendance",
        icon: <BrowseGalleryOutlinedIcon />,
        subRoutes: [
            {
                path: "attendance",
                name: "Attendance",
                icon: <BrowseGalleryOutlinedIcon />,
            },
            {
                path: "lopAttendanceLeaveSummary",
                name: "Attendance Summary",
                icon: <BrowseGalleryOutlinedIcon />,
            },
        ]
    },
    {
        path: "leave",
        name: "Leave",
        icon: <FlightTakeoffIcon />,
        subRoutes: [
            {
                path: "leave",
                name: "Leave Details",
                icon: <HolidayVillageIcon />,
            },
            {
                path: "leave-review",
                name: "Leave Review",
                icon: <FcLeave size={22} color="var(--primaryColor)" />,
            },
            {
                path: "work-from-home",
                name: "Work From Home",
                icon: <MdOutlineMapsHomeWork size={22} color="var(--primaryColor)" />,
            },
        ],
    },
    {
        path: "payroll",
        name: "Payroll",
        icon: <AccountBalanceOutlinedIcon />,
        subRoutes: [
            {
                path: "payroll",
                name: "Salary Details",
                icon: <FcLeave size={22} color='var(--primaryColor' />
            },
            {
                path: "salarySlip",
                name: "Salary Slip",
                icon: <FcLeave size={22} color='var(--primaryColor' />
            }
        ]
    },
    {
        path: "holiday",
        name: "Holiday",
        icon: <HolidayVillageIcon />,
    },
    {
        path: "teamManagement",
        name: "Team",
        icon: <Diversity3Icon />,
    }
];
const userRoutes: SidenavModel[] = [
    {
        path: "dashboard",
        name: "Dashboard",
        icon: <BiSolidDashboard size={25} color="var(--primaryColor)" />
    },
    {
        path: "attendance",
        name: "Attendance",
        icon: <BrowseGalleryOutlinedIcon />,
        subRoutes: [
            {
                path: "attendance",
                name: "Attendance",
                icon: <BrowseGalleryOutlinedIcon />
            },
            {
                path: "userLopAttendanceLeaveSummary",
                name: "Attendance Summary",
                icon: <BrowseGalleryOutlinedIcon />,
            }
        ]
    },
    {
        path: "leave",
        name: "Leave",
        icon: <FlightTakeoffIcon />,
        subRoutes: [
            {
                path: "leave",
                name: "Leave Details",
                icon: <HolidayVillageIcon />,
            },
            {
                path: "leave-review",
                name: "Leave Review",
                icon: <FcLeave size={22} color="var(--primaryColor)" />,
            },
            {
                path: "work-from-home",
                name: "Work From Home",
                icon: <MdOutlineMapsHomeWork size={22} color="var(--primaryColor)" />,
            },
        ],
    },
    {
        path: "payroll",
        name: "Payroll",
        icon: <AccountBalanceOutlinedIcon />,
        subRoutes: [
            {
                path: "payroll",
                name: "Salary Details",
                icon: <FcLeave size={22} color='var(--primaryColor' />
            },
            {
                path: "salarySlip",
                name: "Salary Slip",
                icon: <FcLeave size={22} color='var(--primaryColor' />
            }
        ]
    },
    {
        path: "holiday",
        name: "Holiday",
        icon: <HolidayVillageIcon />,
    },
    {
        path: "teamManagement",
        name: "Team",
        icon: <Diversity3Icon />,
    }
];


interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window?: () => Window;
    children?: React.ReactNode;
}

const SideBar = (props: Props) => {
    const { window, children } = props;
    let LoginPersonName = localStorage.getItem("userName")
    const [sidNavMenuItem, setSideNavMenuItem] = React.useState<any[]>([])
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);
    const [openSub, setOpenSub] = React.useState<string | null>(null);
    const navigate = useNavigate();
    const [loginState, setLoginState] = React.useState<boolean>(false)
    const token = localStorage.getItem("loginToken") || ''
    console.log(token, "Token Check")
    let user: JwtModel;
    if (token !== '') {
        user = jwt(token);
        console.log(user)
    }

    React.useEffect(() => {
        const loginStatus = checkLoginType()
        setLoginState(loginStatus)
        if (loginStatus) {
            setSideNavMenuItem(adminRoutes)
        } else {
            setSideNavMenuItem(userRoutes)
        }

    }, [LoginPersonName])


    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const routesPage = (text: any) => {
        navigate(text.path)
    }

    const handleItemClick = (text: any) => {
        setOpenSub(openSub === text.path ? null : text.path);

    }

    const handleLogOut = (popupState: any) => {
        localStorage.clear()
        if (globalRouter.navigate !== null)
            globalRouter.navigate('/')
    };

    const handleAccount = (popupState: any) => {
        popupState.close();
        if (loginState) {
            navigate("AdminAccount")
        } else {
            navigate("UserAccount")
        }
    }
    const handleIcon = () => {
        if (loginState) {
            navigate('dashboard')
        } else {
            navigate('dashboard')
        }
    }

    const drawer = (
        <div className='drawer-container'>
            <div style={{ height: '100%' }}>
                <div className='drawer-heading'>
                    <Toolbar className='sidebar-head' sx={{ cursor: 'pointer' }} onClick={handleIcon}>
                        <Typography variant="h6" noWrap component="div" style={{ width: '100%', }}>
                            <img src={require('../../assets/logo/Logo Transparent 5.png')} alt='n' style={{ height: '40px', width: '100px' }} />
                        </Typography>
                    </Toolbar>
                </div>
                <div className='sidebar-list-conatiner'>
                    <div style={{ width: '100%' }}>
                        <List style={{ color: 'var(--primaryColor)' }}>
                            {sidNavMenuItem.map((text, index) => (
                                <React.Fragment key={index}>
                                    <ListItem disablePadding onClick={() => {
                                        routesPage(text);
                                        text.subRoutes && handleItemClick(text);
                                    }
                                    } >
                                        <ListItemButton >
                                            <ListItemIcon style={{ color: 'var(--primaryColor)' }}>
                                                {text.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={text.name} />
                                            {
                                                text.subRoutes ? (openSub === text.path ? <ExpandMore /> : <ExpandLess />) : ''
                                            }
                                        </ListItemButton>
                                    </ListItem>
                                    {
                                        text.subRoutes && text.subRoutes.length > 0 &&
                                        <Collapse in={openSub === text.path}>
                                            <List sx={{ pl: 4 }}>
                                                {text.subRoutes.map((subText: any, subIndex: number) => (
                                                    <ListItem key={subIndex} disablePadding onClick={() => routesPage(subText)}>
                                                        <ListItemButton>
                                                            <ListItemIcon style={{ color: 'var(--primaryColor)' }}>
                                                                {subText.icon}
                                                            </ListItemIcon>
                                                            <ListItemText primary={subText.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    }
                                </React.Fragment>
                            ))}
                        </List>
                    </div>
                    <div style={{ width: '100%' }}>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <React.Fragment>
                                    <div {...bindTrigger(popupState)} className='profile-menu-container'>
                                        <div className="icon">

                                            <Avatar className='avatar' alt={user?.name} src="/static/images/avatar/1.jpg" />
                                        </div>
                                        <div>
                                            {LoginPersonName}
                                        </div>

                                    </div>


                                    <Menu {...bindMenu(popupState)} className='profile-menu' style={{ flexDirection: 'column' }}>

                                        <MenuItem onClick={() => handleAccount(popupState)}>My account</MenuItem>
                                        <MenuItem onClick={() => handleLogOut(popupState)}>Logout</MenuItem>
                                    </Menu>
                                </React.Fragment>
                            )}
                        </PopupState>
                    </div>
                </div>
            </div>
        </div >
    );


    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" style={{ width: '100%', cursor: 'pointer' }} onClick={handleIcon}>
                        HRMS
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
            >
                <Toolbar />
                <Typography>
                    {children}
                </Typography>
            </Box>
        </Box>
    );
}

export default SideBar;