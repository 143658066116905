import { useDispatch } from "react-redux"
import { deleteTeam, getAllEmployee, getHoliday, getSingleEmployeeTeamDetails, getTeamDetails } from "../api-services/employee-services"
import { setAdminTeam, setAttendance, setEmployee, setHoliday, setLeave, setSalary, setUserTeam } from "../redux/actions/actions"
const FetchApi = () => {
  const dispatch = useDispatch()
  const fetchAllEmployee = () => {
    getAllEmployee().then((response: any) => {
      if (response?.data.success) {
        dispatch(setEmployee(response.data.payload))
      }
    })
  }


  const fetchHoliday = () => {
    getHoliday().then((response: any) => {
      if (response?.data) {
        const data = response.data
        data.forEach((element: any, index: number) => {
          element['sno'] = index + 1
        });
        dispatch(setHoliday(response.data))
      }
    })
  }

  const fetchAdminTeam = () => {
    getTeamDetails().then((response: any) => {
      if (response?.data.success) {
        const data = response.data.payload
        data.forEach((element: any, index: number) => {
          element['sno'] = index + 1
        });
        dispatch(setAdminTeam(response.data.payload))
      }
    })
  }

  const fetchUserTeam = () => {
    getSingleEmployeeTeamDetails().then((response: any) => {
      console.log(response)
      if (response?.data.success) {
        const data = response.data.payload
        data.forEach((element: any, index: number) => {
          element['sno'] = index + 1
        });
        dispatch(setUserTeam(response.data.payload))
      }
    })
  }

  const deleteTeams = (id: any) => {
    deleteTeam(id).then((response: any) => {

    })
  }

  return { fetchAllEmployee, fetchHoliday, fetchAdminTeam, deleteTeams, fetchUserTeam };
}
export default FetchApi;