import FetchApi from '../../fetch-api/fetch-api';
import { deleteTeam } from '../../api-services/employee-services';
import jwt from 'jwt-decode';
import { JwtModel } from '../../model/token-model/token.model';
import { checkLoginType } from '../../services/loginType';
import { SnackbarService } from '../../services/behaviorSubject/behaviorSubject';
import ButtonWrapper from '../../widgets/button/button';

const EditTeamDetails = (props: { value: any, onActionTrigger?: any }) => {
    const snackbarService = SnackbarService()
    const token = localStorage.getItem("loginToken") || '';
    let user: JwtModel = jwt(token);
    console.log(user)
    const fetchApi = FetchApi()
    const isAdmin = checkLoginType()
    const onDeleteProject = (value: any) => {
        console.log(value.data.projectId)
        deleteTeam(value.data.projectId).then((response: any) => {
            if (response?.data.success) {
                snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
                if (isAdmin || user.roles === 'COREMEMBERS') {
                    fetchApi.fetchAdminTeam()
                } else {
                    fetchApi.fetchUserTeam()
                }
            } else {
                snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
            }
        })
    }

    const onEditProject = () => {
        props.onActionTrigger({ action: 'edit', data: props.value })
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <ButtonWrapper buttonType='delete' onClick={() => onDeleteProject(props.value)} element='project' tooltipTitle='Delete' tooltipPlacement='left' />
            <ButtonWrapper buttonType='edit' className='edit-employee-button' onClick={onEditProject} tooltipTitle='Edit' tooltipPlacement='right' />
        </div >
    )
}

export default EditTeamDetails
