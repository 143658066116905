import React, { useEffect, useState } from 'react'
import './teamManagement.css'
import { Tooltip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AddTeamProject from '../../../componant/addTeamProject/addTeamProject';
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomTeamDetailsCell from '../../custom-team-details-cell-render/customTeamDetailsCell';
import EditTeamDetails from '../../edit-team-details-cell/editTeamDetails';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import FetchApi from '../../../fetch-api/fetch-api';
import { useSelector } from 'react-redux';
import { SnackbarService } from '../../../services/behaviorSubject/behaviorSubject';
import ButtonWrapper from '../../../widgets/button/button';
import ModalWrapper from '../../../widgets/modal/modal';

const style = {

    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: 0.9,
        sm: 0.8,
        md: 400
    },
    bgcolor: 'white',
    boxShadow: 24,
    p: 2,
    borderRadius: 2

};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));


const TeamManagement = () => {
    const snackbarService = SnackbarService()
    const state = useSelector((state: any) => state.team)
    const fetchApi = FetchApi()
    const [actionStatus, setActionStatus] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [rawData, setRawData] = useState([])
    const [selectedRow, setSelectedRow] = useState(null)

    const handleClose = () => {
        setOpenModal(false)
        setSelectedRow(null)
    };

    const onCreateTeam = () => {
        setOpenModal(true)
        setActionStatus(false)
    }

    useEffect(() => {
        fetchApi.fetchAdminTeam()
    }, [])
    useEffect(() => {

        if (state?.admin_team !== null) {
            setRawData(state.admin_team)
        } else {
            setRawData([])
        }
    }, [state])
    console.log(rawData, "Data")

    const columnDefs: any = [
        {
            headerName: "S.No",
            field: "sno",
            width: 100,
        },
        {
            headerName: "Project Name",
            field: "projectName",
            width: 200,
        },
        {
            headerName: "Client",
            field: "projectClient",
            width: 200,
        },
        {
            headerName: "Details",
            field: "projectDetails",
            width: 200,
        },
        {
            headerName: "Start Date",
            field: "projectStartDate",
            width: 200,
        },
        {
            headerName: "End Date",
            field: "projectDeliveryDate",
            width: 200,
        },
        {
            headerName: "Demo Date",
            field: "projectDemoDate",
            width: 200,
        },
        {
            headerName: "Project Leader",
            field: "projectLed",
            width: 200,
            filter: true,
            floatingFilter: true,

        },
        {
            headerName: "Fontend Member",
            field: "projectFrontEndMembers",
            width: 200,
            cellRenderer: (params: any) => (
                <CustomTeamDetailsCell value={params} actionStatus={true} />
            )

        },
        {
            headerName: "Backend Member",
            field: "projectBackEndMembers",
            width: 200,
            cellRenderer: (params: any) => (
                <CustomTeamDetailsCell value={params} />
            )

        },
        {
            headerName: "Status",
            field: "projectWorkingStatus",
            width: 200,
        },
        {
            headerName: "Remarks",
            field: "projectRemark",
            width: 200,
        },
        {
            headerName: "Action",
            field: "",
            width: 200,
            cellRenderer: (params: any) => (
                <EditTeamDetails value={params} onActionTrigger={onActionClick} />
            )

        }
    ]
    const onActionClick = (opt: any) => {
        switch (opt.action) {
            case 'edit':
                setSelectedRow(opt.data)
                setOpenModal(true)
                setActionStatus(true)
                break;
        }
    }


    return (
        <div className='main'>
            <div className='main-container'>
                <div className='admin-team-heading'>
                    <h2>Team Management</h2>
                    <div className='add-team-button-container'>
                        <ButtonWrapper buttonType='circle' className='circular-add-button' onClick={onCreateTeam} tooltipPlacement='left' tooltipTitle='Create Team' />
                    </div>
                    <ModalWrapper open={openModal} close={handleClose} children={<AddTeamProject onClose={handleClose} actionStatus={actionStatus} data={selectedRow} />} />
                </div>
                <div className='admin-team-sub-container'>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={rawData}
                        editType={'fullRow'}
                        className="ag-theme-alpine"
                        suppressRowClickSelection={true}
                        enableRangeSelection={true}
                        stopEditingWhenCellsLoseFocus={true}
                        singleClickEdit={true}
                    />
                </div>

            </div>
        </div >
    )
}

export default TeamManagement