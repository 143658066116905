import React, { ReactNode } from 'react'
import { Box, Modal } from '@material-ui/core';

interface ModalWrapperProps {
    open: boolean;
    close: () => void;
    children: ReactNode
}

const ModalWrapper = (props: ModalWrapperProps) => {
    const style = {

        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: 0.9,
            sm: 0.8,
            md: 400
        },
        bgcolor: 'white',
        boxShadow: 24,
        p: 2,
        borderRadius: 2

    };
    return (
        <>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modal-class'
            >
                <Box sx={style}>
                    {props.children}
                </Box>
            </Modal>
        </>
    )
}

export default ModalWrapper
