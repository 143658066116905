import React, { useEffect, useState } from 'react'
import './lopAttendanceLeaveSummary.css'
import { detailsField } from '../../../../services/employeeFieldType'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getAttendanceSummaryWithLop } from '../../../../api-services/employee-services';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { formateDate, formateTimeZone } from '../../../../services/constant';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Button, FormControl, TextField, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { utils } from 'xlsx';
import { writeFile } from 'xlsx';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';


const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 1, 0, 1)',
        fontSize: 11,
    },
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'var(--primaryColor)',
        color: theme.palette.common.white,
        border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface lopDetailsProps {
    lossOfPay: string,
    present: string,
    leave: string,
    date: string
}

interface lopAttendanceLeaveSummaryDataProps {
    employeeId: string,
    employeeName: string,
    designation: string,
    lopDetails: lopDetailsProps[]
}



const LopAttendanceLeaveSummary = () => {
    const inputField = detailsField().dateRangeField
    const [selectedDate, setSelectedDate] = useState<any>({
        startDate: '',
        endDate: ''
    });

    const [rawData, setRawData] = useState<lopAttendanceLeaveSummaryDataProps[]>([])
    const [sortedallAttendance, setSortedAllAttendance] = useState<lopAttendanceLeaveSummaryDataProps[]>([])
    const [showDownloadButton, setShowDownloadButton] = useState(false)
    const [downloadAttendanceData, setDownloadAttendanceData] = useState<lopAttendanceLeaveSummaryDataProps[]>([])
    const { control } = useForm();

    function format(dateString: any) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}/${month}/${year}`;
    }

    console.log(downloadAttendanceData, "Download Data")

    const fetchData = () => {
        getAttendanceSummaryWithLop().then((response: any) => {
            console.log(response)
            if (response.data?.success) {

                response.data.payload.forEach((employee: any) => {
                    employee.lopDetails.forEach((detail: any) => {
                        detail.date = format(detail.date);
                    });
                });
                setRawData(response.data.payload)
                filterDataForSelectedDate(null, null, response.data.payload)
            }
        }).catch((err) => {

        });
    }

    useEffect(() => {
        fetchData()
    }, [])


    useEffect(() => {
        if (selectedDate && selectedDate.startDate !== '' && selectedDate.endDate !== '') {
            const startDate = new Date(selectedDate.startDate);
            startDate.setHours(0, 0, 0, 0)
            const endDate = new Date(selectedDate.endDate);
            endDate.setHours(0, 0, 0, 0)
            filterDataForSelectedDate(startDate, endDate)

        }
    }, [selectedDate])

    useEffect(() => {
        const startDate = new Date(selectedDate.startDate);
        startDate.setHours(0, 0, 0, 0)
        let endDate: Date | undefined = undefined;
        if (selectedDate.endDate !== '') {
            endDate = new Date(selectedDate.endDate);
            endDate.setHours(0, 0, 0, 0)
        }
        if (endDate) {
            if (startDate > endDate) {
                setSelectedDate((preav: any) => {
                    return { ...preav, endDate: '' }
                })
            }
        }


    }, [selectedDate.endDate, selectedDate.startDate])


    function formatDateToCustomFormat(inputDate: any) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const customFormat = `${year}-${month}-${day}`; return customFormat;
    }

    const handleDate = (e: any, field: any) => {
        const value = formatDateToCustomFormat(e.$d.toLocaleDateString("en-US")).toString()
        setSelectedDate((preav: any) => {
            return { ...preav, [field]: value }
        })

    }

    const formateDates = (data: any) => {
        const currentDate = data.split('/', 3)
        const FormateDate = `${currentDate[2]}-${currentDate[1]}-${currentDate[0]}`
        const newFormateDate = new Date(FormateDate)
        return newFormateDate;
    }

    console.log(sortedallAttendance, "Sorted Data")
    console.log(rawData, "raw Data")

    const filterDataForSelectedDate = (startDateData?: any, endDateData?: any, response?: lopAttendanceLeaveSummaryDataProps[]) => {
        if (response !== undefined) {
            var attendanceData = response
            setSortedAllAttendance(response)
            console.log(response, "response")
        } else {
            var attendanceData = rawData
        }
        const today = new Date()
        console.log(today)
        let startDate: Date = new Date(startDateData !== null ? startDateData : today)
        let endDate: Date = new Date(endDateData !== null ? endDateData : today)
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(0, 0, 0, 0)
        console.log(startDate)
        console.log(endDate)
        const timeDifference = endDate.getTime() - startDate.getTime();
        const numberOfDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;

        const filteredData = attendanceData?.map((employee: any) => ({
            ...employee,
            lopDetails: employee.lopDetails.filter((detail: lopDetailsProps) => {
                if (detail.date !== undefined) {
                    const detailDate = formateDates(detail.date);
                    console.log(detailDate)
                    detailDate.setHours(0, 0, 0, 0)
                    if (numberOfDays > 1) {
                        return detailDate >= startDate && detailDate <= endDate;
                    } else {
                        console.log(detailDate)
                        console.log(startDate)
                        console.log(endDate)
                        return detailDate.getTime() === startDate.getTime() && detailDate.getTime() === endDate.getTime();
                    }
                }

            }),
        }));

        console.log(filteredData)
        setShowDownloadButton(true);
        setDownloadAttendanceData(filteredData)
        setSortedAllAttendance(filteredData)
        // if (numberOfDays > 7) {
        //     setShowDownloadButton(true);
        //     setDownloadAttendanceData(filteredData)
        // } else if (numberOfDays <= 7) {
        //     setShowDownloadButton(false);
        //     setSortedAllAttendance(filteredData)
        // }

    };

    const handleDownloadAttendanceDetails = (e: any) => {
        const header = ['Employee Id', 'Employee Name', 'Designation', 'Date', 'Present', 'Leave', 'Loss Of Pay'];

        const newData: any = [];
        const encounteredEmployeeIds: Set<string> = new Set();
        let totalPresent = 0;
        let totalLeave = 0;
        let totalLop = 0;
        let total = totalPresent + totalLeave + totalLop
        downloadAttendanceData.map((employee: any) => {
            // Extract employee information
            const employeeId = employee.employeeId;
            const employeeName = employee.employeeName;
            const designation = employee.designation;

            let presentCount = 0;
            let leaveCount = 0;
            let lopCount = 0;

            // Iterate over the "attendanceDetails" array for each employee
            employee.lopDetails.forEach((lopDetails: any) => {
                const date = lopDetails.date;
                const leave = lopDetails.leave;
                const present = lopDetails.present;
                const lossOfPay = lopDetails.lossOfPay;
                // Create a row of data and push it to newRowData
                // const rowData = [employeeId, employeeName, designation, date, present, leave, lossOfPay];

                if (present === "Yes") {
                    presentCount++;
                    totalPresent++;
                }
                if (leave === "Yes") {
                    leaveCount++;
                    totalLeave++;
                }
                if (lossOfPay === "Yes") {
                    lopCount++;
                    totalLop++;
                }


                // newData.push(rowData);
                const employeeInfoTuple = `${employeeId}-${employeeName}-${designation}`;
                if (!encounteredEmployeeIds.has(employeeInfoTuple)) {
                    // Create a row of data and push it to newData
                    const rowData = [employeeId, employeeName, designation, date, present, leave, lossOfPay];
                    newData.push(rowData);
                    // console.log(rowData)

                    // Add the employeeId to encounteredEmployeeIds set
                    encounteredEmployeeIds.add(employeeInfoTuple);
                } else {
                    // If it's a duplicate, push an empty employeeId
                    const rowData = ["", "", "", date, present, leave, lossOfPay];
                    newData.push(rowData);
                }
            });
            newData.push(["", "", "", "", "", "", ""]);
            newData.push(["", "", "", `Total=${totalPresent + totalLeave + totalLop}`, totalPresent, totalLeave, totalLop]);
            newData.push(["", "", "", "", "", "", ""]);
            totalPresent = 0;
            totalLeave = 0;
            totalLop = 0;

        });


        let newData1: any = downloadAttendanceData.map((employee: any) => {
            return {
                employeeId: employee.employeeId,
                employeeName: employee.employeeName,
                designation: employee.designation,
                present: employee.lopDetails.filter((lop: any) => lop.present === "Yes").length,
                leave: employee.lopDetails.filter((lop: any) => lop.leave === "Yes").length,
                lossOfPay: employee.lopDetails.filter((lop: any) => lop.lossOfPay === "Yes").length,

            };
        });
        // if (newData1.length === 0) {
        //     newData1 = [...result];
        //     // console.log(result, "Inner")
        // }

        // console.log(result, "Result")
        console.log(newData1, "New Data1")
        console.log(newData, "New Data")


        // Create a worksheet with the header and data
        const ws = utils.aoa_to_sheet([header, ...newData]);
        const workSheet = utils.json_to_sheet(newData1);
        // Create a new workbook and add the worksheet
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Details');
        utils.book_append_sheet(wb, workSheet, 'Total No of Days');
        // utils.book_append_sheet(wb, ws1, 'Attendance Data');

        // Save the workbook as an Excel file
        writeFile(wb, 'Attendance Summary.xlsx');

    }



    const today = new Date().toISOString().split('T')[0];
    console.log(today, "today")
    return (
        <div className='main'>
            <div className='main-container'>
                <div className='lop-attendance-heading'>
                    <div className='lop-attendance-sub-heading'>
                        <div className='heading-container'>
                            <h2>Attendance Summary</h2>
                        </div>
                        <div className='lop-date-range-container'>
                            <div className='lop-sub-date-range'>
                                {
                                    inputField.map((item: any, index: number) => {
                                        return <div key={index} className='attendance-date-container'>
                                            <div className='attendance-dates-container'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        label={item.label}
                                                        format='DD/MM/YYYY'
                                                        minDate={item.name === 'endDate' && selectedDate.startDate ? dayjs(selectedDate.startDate) : undefined}
                                                        onChange={(e) => handleDate(e, item.name)}
                                                        value={selectedDate[item.name]}
                                                        closeOnSelect={true}
                                                    />
                                                </LocalizationProvider>
                                            </div >
                                        </div>

                                    })
                                }
                            </div>
                            <div className='lop-download-button-main-container'>
                                {showDownloadButton ? <LightTooltip title='Download Excel'><Button
                                    variant='contained'
                                    onClick={(e) => handleDownloadAttendanceDetails(e)}
                                    className='lop-download-button-container'
                                ><DownloadIcon /></Button></LightTooltip> : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lop-attendance-sub-container'>
                    <div className='lop-sub-table-container'>

                        <TableContainer className='mui-table-container'>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Id</StyledTableCell>
                                        <StyledTableCell align="left">Name</StyledTableCell>
                                        <StyledTableCell align="left">
                                            <div className='table-cell-container'>

                                                <p className='width25'>Date</p>
                                                <p className='width25'>Present</p>
                                                <p className='width25'>Leave</p>
                                                <p className='width25'>Loss of Pay</p>
                                            </div>
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedallAttendance.map((row) => (
                                        <StyledTableRow key={row.employeeId}>
                                            <TableCell component="td" scope="row" sx={{ fontSize: '14px' }}>
                                                {row.employeeId}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {row.employeeName}
                                            </TableCell>
                                            {
                                                row.lopDetails?.map((lop) => {

                                                    return <TableCell align='left' sx={{ display: 'flex', borderLeft: '1px solid #F5F5F5' }}>
                                                        <p className='body-width25'>{lop.date}</p>
                                                        <p className='body-width25'>{lop.present}</p>
                                                        <p className='body-width25'>{lop.leave}</p>
                                                        <p className='body-width25'>{lop.lossOfPay}</p>
                                                    </TableCell>


                                                })
                                            }
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default LopAttendanceLeaveSummary
