import React, { useEffect, useState } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './workFromHome.css'
import { getWorkFromHomeForUser } from '../../../../api-services/employee-services';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip, IconButton, Modal } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { AgGridReact } from "ag-grid-react";
import { FcApproval } from 'react-icons/fc';
import { AiFillCloseCircle, AiOutlineRedo } from 'react-icons/ai';
import WorkFromHomeApplication from '../../../../componant/workFromHomeApplication/workFromHomeApplication';
import CustomDetailsCellRender from '../../../custom-details-cell-render/custom-details-cell-render';
import ButtonWrapper from '../../../../widgets/button/button';
import ModalWrapper from '../../../../widgets/modal/modal';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 1, 0, 1)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const style = {

    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: 0.9,
        sm: 0.8,
        md: 400
    },
    bgcolor: 'white',
    boxShadow: 24,
    p: 2,
    borderRadius: 2

};

interface workFromHomeDataProps {
    wfhId: number,
    fromDate: string
    toDate: string,
    reasonForWFH: string,
    noOfDays: number,
    leaveStatus: string
}





const UserWorkFromHome = () => {

    const [rawData, setRawData] = useState<workFromHomeDataProps[]>([])
    const [openModal, setOpen] = useState<boolean>(false);
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    const fetchData = () => {
        getWorkFromHomeForUser().then((response: any) => {
            if (response.data?.success) {
                setRawData(response.data.payload.wfhDetails)
            }
        }).catch((err) => {

        });
    }

    useEffect(() => {
        fetchData()
    }, [])

    const [columnDefs] = useState<any[]>([
        {
            headerName: "From Date",
            field: "fromDate",
            width: 200
        },
        {
            headerName: "To Date",
            field: "toDate",
            width: 200
        },
        {
            headerName: "Days",
            field: "noOfDays",
            width: 145
        },
        {
            headerName: "Reason",
            field: "reasonForWFH",
            cellRenderer: (params: any) => (<CustomDetailsCellRender data={params} flag={true} />),
            width: 200
        },
        {
            headerName: "Leave Status",
            field: "leaveStatus",
            cellRenderer: (params: any) => (
                <div>{params.data.leaveStatus === 'approved' ? <FcApproval /> : params.data.leaveStatus === 'rejected' ? <AiFillCloseCircle color='var(--dangerColor)' /> : <AiOutlineRedo color="var(--secondaryColor)" />}{params.data.leaveStatus}</div>

            ),
            width: 200
        }
    ]);

    const handleWorkFromHome = () => {
        handleOpen()
    }

    const handleEditEmployees = (params: any) => {
        if (params)
            fetchData()
    }

    return (
        <>
            <div className='main'>
                <div className='main-container'>
                    <Stack height='100%'>
                        <div className='heading4'>
                            <h2>Work From Home</h2>
                            <ButtonWrapper buttonType='circle' className='circular-add-button' tooltipTitle='Apply Work From Home' tooltipPlacement='left' onClick={handleWorkFromHome} />
                            <ModalWrapper open={openModal} close={handleClose} children={<WorkFromHomeApplication onClose={handleClose} handleEditEmployees={handleEditEmployees} />} />
                        </div>
                        <div className='wfh-sub-container'>
                            <AgGridReact
                                rowData={rawData}
                                columnDefs={columnDefs}
                                className="ag-theme-alpine"

                            />
                        </div>
                    </Stack>
                </div>

            </div >
        </>
    )
}

export default UserWorkFromHome
