import React, { useEffect, useState } from 'react'
import './payroll.css'
import { useSelector } from 'react-redux'
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CurrencyCellRenderer from '../../currency-cell-render/currency-cell-render';
import { currencyCode } from '../../../services/constant';
import { getAllEmployeeSalaryDetails } from '../../../api-services/employee-services';
import { useDispatch } from 'react-redux';
import { setSalary } from '../../../redux/actions/actions';


const AdminPayroll = () => {
    const employeeState = useSelector((state: any) => state.allEmployee)
    const dispatch = useDispatch()
    const [gridApi, setGridApi] = useState<any | null>(null)
    const [gridColumnApi, setGridColumnApi] = useState(null)
    const [rawData, setRawData] = useState<any[]>([])
    const currency = currencyCode()
    useEffect(() => {
        getAllEmployeeSalaryDetails().then((response: any) => {
            if (response?.data.success) {
                setRawData(response.data.payload)
                dispatch(setSalary(response.data.payload))
            }
        })
    }, [])
    useEffect(() => {
        if (employeeState.salary.length > 0) {
            setRawData(employeeState.salary)
        }
    }, [employeeState])
    const onGridReady = (params: any) => {
        setGridApi(params.api)
        setGridColumnApi(params.columnApi)
    }
    const columnDefs = [
        {
            headerName: "ID",
            field: "employee_id",
            width: 150,
        },
        {
            headerName: "Name",
            field: "name",
            width: 225,

        },
        {
            headerName: "Designation",
            field: "designation",
            width: 275
        },
        {
            headerName: "Gross Salary",
            field: "grossSalary",
            width: 150,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        },
        {
            headerName: "Basic Salary",
            field: "basicSalary",
            width: 150,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        },
        {
            headerName: "House Rent Allowance",
            field: "houseRentAllowance",
            width: 175,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        },
        {
            headerName: "Dearness Allowance",
            field: "dearnessAllowance",
            width: 175,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        },
        {
            headerName: "Travel Allowance",
            field: "travelAllowance",
            width: 150,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        },
        {
            headerName: "Medical Allowance",
            field: "medicalAllowance",
            width: 175,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        },
        {
            headerName: "Special Allowance",
            field: "specialAllowance",
            width: 175,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        },
        {
            headerName: "Income Tax",
            field: "incomeTax",
            width: 150,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        },
        {
            headerName: "Professional Tax",
            field: "professionalTax",
            width: 175,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        },
        {
            headerName: "Provident Fund",
            field: "providentFund",
            width: 175,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        },
        {
            headerName: "Employee State Insurance",
            field: "employeeStateInsurance",
            width: 200,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        },
        {
            headerName: "Deduction Ammount",
            field: "deductionAmount",
            width: 200,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        },
        {
            headerName: "Net Salary",
            field: "netSalary",
            width: 200,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
        }
    ]

    const handleFilter = (e: any) => {
        gridApi.setQuickFilter(e.target.value)
    }
    return (
        <>
            <div className='main'>
                <div className='main-container'>
                    <div className='admin-payroll-heading'>
                        <h2>Payroll</h2>
                        <div style={{ flexBasis: '50%', maxWidth: '500px' }}>
                            <div style={{ border: '1px solid ', padding: '3px 10px 3px 16px', borderRadius: '50px' }}>
                                <input
                                    style={{ border: 'none' }}
                                    type='search'
                                    id='filterText'
                                    placeholder='Search employee payroll details...'
                                    onChange={handleFilter}
                                    className='search-input'
                                />
                            </div>
                        </div>

                    </div>
                    <div className='admin-payroll-sub-container'>

                        <div className='wraper'>
                            <AgGridReact
                                onGridReady={onGridReady}
                                columnDefs={columnDefs}
                                rowData={rawData}
                                className="ag-theme-alpine"
                            />
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default AdminPayroll


