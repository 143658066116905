import React, { useEffect, useState } from 'react'
import { detailsField } from '../../../services/employeeFieldType'
import './dashboard.css'
import { FaCircle } from "react-icons/fa";
import { AiOutlineRedo } from "react-icons/ai";
import Fab from '@mui/material/Fab';
import { MdOutlineHomeWork } from "react-icons/md";
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import { getAbsentEmployeeSummaryDetails, getBirthdaySummaryDetails, getLeaveSummaryDetails, getProjectSummaryDetails, getSummaryPendingLeave, getWorkFromHomeSummaryDetails } from '../../../api-services/employee-services';
import { CgDetailsMore } from "react-icons/cg";
import { Grid } from '@material-ui/core';
// const detailsFields = () => {
//     const field = detailsField()
//     return field
// };

const AdminDashboard = () => {
    const field = detailsField()
    const [fieldValue, setFieldValue] = useState<any[]>([])
    const navigate = useNavigate()
    // const fetchData = () => {
    //     getSummaryPendingLeave().then((result: any) => {
    //         if (result.data?.success) {
    //             setFieldValue((preav) => [...preav, result.data.payload])
    //         } else {
    //             const updatedPayload = {
    //                 ...result.data.payload,
    //                 summaryDetails: result.data.payload.summaryDetails !== null ? result.data.payload.summaryDetails : []
    //             };
    //             setFieldValue((preav) => [...preav, updatedPayload])
    //         }
    //     })

    //     getBirthdaySummaryDetails().then((result: any) => {
    //         if (result.data?.success) {
    //             setFieldValue((preav) => [...preav, result.data.payload])
    //         } else {
    //             const updatedPayload = {
    //                 ...result.data.payload,
    //                 summaryDetails: result.data.payload.summaryDetails !== null ? result.data.payload.summaryDetails : []
    //             };
    //             setFieldValue((preav) => [...preav, updatedPayload])
    //         }
    //     })
    //     getLeaveSummaryDetails().then((result: any) => {
    //         if (result.data?.success) {
    //             setFieldValue((preav) => [...preav, result.data.payload])
    //         } else {
    //             const updatedPayload = {
    //                 ...result.data.payload,
    //                 summaryDetails: result.data.payload.summaryDetails !== null ? result.data.payload.summaryDetails : []
    //             };
    //             setFieldValue((preav) => [...preav, updatedPayload])
    //         }
    //     })

    // }

    const fetchData1 = () => {
        getWorkFromHomeSummaryDetails().then((response: any) => {
            console.log(response)

        })
    }

    useEffect(() => {
        fetchData1()
    }, [])

    const fetchData = async () => {
        try {
            const [
                summaryPendingLeaveResult,
                birthdaySummaryDetailsResult,
                leaveSummaryDetailsResult,
                projectSummaryDetailsResult,
                absentEmployeeSummaryDetails,
                workFromHomeEmployeeSummaryDetails,

            ] = await Promise.allSettled([
                getSummaryPendingLeave(),
                getBirthdaySummaryDetails(),
                getLeaveSummaryDetails(),
                getProjectSummaryDetails(),
                getAbsentEmployeeSummaryDetails(),
                getWorkFromHomeSummaryDetails()
            ]);

            handleApiResult(projectSummaryDetailsResult);
            handleApiResult(summaryPendingLeaveResult);
            handleApiResult(birthdaySummaryDetailsResult);
            handleApiResult(leaveSummaryDetailsResult);
            handleApiResult(absentEmployeeSummaryDetails);
            handleApiResult(workFromHomeEmployeeSummaryDetails);
        } catch (error) {
            console.error('One or more API calls failed:', error);
        }
    };

    const handleApiResult = (result: any) => {
        if (result.status === 'fulfilled') {
            if (result.value !== null) {
                const data = result.value.data;
                if (data?.success) {
                    setFieldValue((prev) => [...prev, data.payload]);
                } else {
                    const updatedPayload = {
                        ...data.payload,
                        summaryDetails:
                            data.payload.summaryDetails !== null
                                ? data.payload.summaryDetails
                                : []
                    };
                    setFieldValue((prev) => [...prev, updatedPayload]);
                }
            }
        } else {
            console.error('API call failed:', result.reason);
        }
    };



    useEffect(() => {
        fetchData()
    }, [])


    useEffect(() => {
        const updateFieldValues = (fieldName: any, fieldArray: any) => {
            if (fieldArray.length > 0) {
                setFieldValue((prevValue) => [...prevValue, ...fieldArray]);
            }
        };

        // updateFieldValues('attendance', field.leave);
        // updateFieldValues('project', field.project);
        // updateFieldValues('absent', field.absent);
    }, []);


    console.log(fieldValue, "field value")

    const getMonthName = (monthNumber: any) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('en-US', { month: 'long' });
    }
    const formateDateWithOutYear = (opt: any) => {
        const newDate = new Date(opt)
        const date = newDate.getDate()
        const month = getMonthName(newDate.getMonth() + 1)
        const updateDate = `${date} ${month}`
        return updateDate;
    }

    const formateDate = (opt: any) => {
        const splitDate = opt.split('-')
        const year = splitDate[0]
        const month = splitDate[1]
        const date = splitDate[2]
        const updateDate = `${date}-${month}-${year}`
        return updateDate;
    }

    const onViewDetails = (e: any, field: any) => {
        let path = '';

        switch (field) {
            case 'pendingLeaveForApproval':
                path = '/admin/leave-review';
                break;
            case 'workFromHome':
                path = '/admin/work-from-home'
                break;
            default:
                path = '/admin/teamManagement';
                break;
        }
        navigate(path);
    }

    return (
        <div className='main'>
            <div className='main-container'>
                <Grid container className='heading' xs={12}>
                    <h2>Dashboard</h2>
                </Grid>
                <div className='sub-container'>
                    <div className='sub-container-wrapper'>

                        <Grid container spacing={1}>
                            {
                                fieldValue.map((element: any, index: number) => {
                                    return <>
                                        <Grid item xs={12} sm={6} md={4}>

                                            <div className="flex-item" key={index}>
                                                <div style={{ height: '100%' }}>
                                                    <div className='flex-item-heading'>


                                                        <p style={{ flexBasis: '250px', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex' }}>{element.label}</p>
                                                        {
                                                            element.name !== 'leave' && element.name !== 'birthday' && element.name !== 'absent' && (<Box sx={{
                                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-end', '& > :not(style)': { m: 0.50 }, '.MuiFab-root': {
                                                                    backgroundColor: 'var(--tertiarycolor)',
                                                                    color: 'var(--primaryColor)',
                                                                    fontWeight: '600',
                                                                    border: '1px solid var(--primaryColor)',
                                                                    padding: '1%',
                                                                    '&:hover': {
                                                                        backgroundColor: 'var(--primaryColor)',
                                                                        color: 'var(--tertiarycolor)',
                                                                    },
                                                                },
                                                            }}>
                                                                <CgDetailsMore style={{ fontSize: '15px', cursor: 'pointer' }} onClick={(e) => onViewDetails(e, element.name)} />
                                                            </Box>)
                                                        }

                                                    </div>

                                                    <div className='dashboard-details-container'>
                                                        {
                                                            element.summaryDetails.map((ele: any, ind: number) => {
                                                                return <div key={ind}>
                                                                    <div className='dashboard-details-item-container'>
                                                                        <div>
                                                                            {ele.name}
                                                                        </div>
                                                                        <div>
                                                                            {ele && ((element.name === 'leave' || element.name === 'absent') && ele.status) ?
                                                                                <FaCircle className={element.name === 'absent' ? 'status-circle-red' : 'status-circle-yellow'} />
                                                                                :
                                                                                (element.name === 'pendingLeaveForApproval' && ele.status === 'Pending' ?
                                                                                    <AiOutlineRedo className='status-outline-redo' />
                                                                                    : (element.name === 'workFromHome' && ele.status === 'WFH' ? <MdOutlineHomeWork className='work-from-home-icon' /> :
                                                                                        (ele.status ? (element.name === 'birthday' ? formateDateWithOutYear(ele.status) : formateDate(ele.status))
                                                                                            : '')))}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div >
                                        </Grid>
                                    </>
                                })
                            }
                        </Grid>
                    </div>

                </div>

            </div>
        </div >
    )
}

export default AdminDashboard