import { Button } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditNoteIcon from '@mui/icons-material/EditNote';
import React from 'react'
import './button.css'
import LightTooltip from '../tooltip/tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from 'sweetalert';
import { ButtonList } from 'sweetalert/typings/modules/options/buttons';

interface ButtonWrapperProps {
    className?: string;
    title?: string;
    color?: string;
    backgroundColor?: string;
    disable?: boolean;
    onClick?: () => void;
    titleStyle?: string;
    type?: any;
    id?: string;
    variant?: any;
    buttonType?: string;
    tooltipTitle?: string;
    tooltipPlacement?: any;
    actionStatus?: any;
    element?: string
}

const ButtonWrapper = (props: ButtonWrapperProps) => {
    let icon = null;
    switch (props.buttonType) {
        case 'circle':
            icon = < AddCircleIcon className={props.className} onClick={props.onClick} />
            break;
        case 'edit':
            icon = <EditNoteIcon className={props.className} onClick={props.onClick} />
            break;
        case 'delete':
            icon = <DeleteIcon
                className='delete-employee-button'
                onClick={() => {
                    swal({
                        title: "Are you sure?",
                        text: `Do you want to Delete this ${props.element}?`,
                        icon: "warning",
                        buttons: true as unknown as ButtonList | (string | boolean)[] | undefined,
                        dangerMode: true,
                    })
                        .then((willDelete) => {
                            if (willDelete !== null) {
                                swal(`Poof! Your ${props.element} has been Deleted!`, {
                                    icon: "success",
                                });
                                if (props.onClick)
                                    props.onClick()

                            } else {
                                swal(`Poof! Your ${props.element} has not been Deleted!`, {
                                    icon: "info",
                                });
                            }
                        });
                }} />
            break;
        default:
            icon = <Button id={props.id} type={props.type} disabled={props.disable} variant={props.variant} className={props.className} onClick={props.onClick} style={{ color: `${props.color}`, backgroundColor: `${props.backgroundColor}` }}><div className={props.titleStyle}>{props.title}</div></Button>
            break;
    }
    return (
        <LightTooltip title={props.tooltipTitle} placement={props.tooltipPlacement}>
            {icon}
        </LightTooltip>

    )
}

export default ButtonWrapper