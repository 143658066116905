import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import './viewWorkFromHomeReasonDetails.css'

interface ViewWorkFromHomeReasonDetailsProps {
    data: any;
    onClose: () => void
}


const ViewWorkFromHomeReasonDetails = (props: ViewWorkFromHomeReasonDetailsProps) => {
    const [reason, setReason] = useState('')
    console.log(props.data, "Data")
    useEffect(() => {
        setReason(props.data.reasonForWFH)
    }, [])
    return (
        <>
            <div style={{ minHeight: 100, paddingBottom: '20px' }}>
                <div className='wfh-details-close-button'>
                    <CloseIcon

                        onClick={props?.onClose}
                    />
                </div>
                <div>{reason}</div>
            </div>
        </>
    )
}

export default ViewWorkFromHomeReasonDetails