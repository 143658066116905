import React from 'react'
import './teamMembersDetails.css'
import CloseIcon from '@mui/icons-material/Close';

interface TeamMembersDetailsProps {
    onClose: () => void
    members: any;
    actionStatus: boolean
}
const TeamMembersDetails = (props: TeamMembersDetailsProps) => {
    console.log(props.members)
    return (
        <>
            <div className='team-members-main-container'>
                <div className='team-members-sub-container'>
                    <h4>{props.actionStatus ? 'Frontend' : 'Backend '} Members</h4>
                    <CloseIcon
                        className='team-members-close-button'
                        onClick={() => { props.onClose() }}
                    />
                </div>
                <div className='team-members-list-container'>
                    {
                        props.members.value.value.map((ele: any, index: number) => {
                            return (
                                <ol className="list list-view-filter" key={index}>
                                    <li>{ele.name}</li>
                                </ol>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default TeamMembersDetails