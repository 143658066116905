import React, { useEffect, useState } from 'react'
import './workFromHome.css'
import { getWorkFromHomeDetails } from '../../../../api-services/employee-services';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Button } from '@mui/material';
import ApproveRejectCell from '../../../custom-cell-render/approve-reject-cell';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip, IconButton, Modal } from '@mui/material';
import ViewWorkFromHomeReasonDetails from '../../../../componant/viewWorkFromHomeReasonDetails/viewWorkFromHomeReasonDetails';
import CardActions from '@mui/material/CardActions';
import dayjs from 'dayjs';
import ButtonWrapper from '../../../../widgets/button/button';
import ModalWrapper from '../../../../widgets/modal/modal';

interface WorkFromHomeProps {
    employeeId: string,
    employeeName: string,
    wfhDetails: WorkFromHomeDetailsProps[]

}
interface WorkFromHomeDetailsProps {
    wfhId: number,
    fromDate: string,
    toDate: string,
    reasonForWFH: string,
    noOfDays: number,
    leaveStatus: string
}

const style = {

    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: 0.9,
        sm: 0.8,
        md: 400
    },
    bgcolor: 'white',
    boxShadow: 24,
    // p: '2px 2px 0px 2px',
    p: 2,
    pt: 1,
    pr: 1,
    borderRadius: 2
};

const WorkFromHome = () => {
    const inputField = [
        { name: 'startDate', label: 'Form Date', type: 'date' },
        { name: 'endDate', label: 'To Date', type: 'date' }
    ]

    const [workFromHomeData, setWorkFromHome] = useState<WorkFromHomeProps[]>([])
    const [sortedWorkFromHomeDate, setSortedWorkFromHomeDate] = useState<WorkFromHomeProps[]>([])
    const [viewData, setViewData] = useState('')

    const [selectedDate, setSelectedDate] = useState<any>({
        startDate: '',
        endDate: ''
    });

    console.log(selectedDate)


    const [openModal, setOpen] = useState<boolean>(false);
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    const fetchData = () => {
        getWorkFromHomeDetails().then((response: any) => {
            if (response?.data.success) {
                setWorkFromHome(response.data.payload)
                filterDataForSelectedDate(null, null, response.data.payload)
            }
        }).catch((err) => {

        });
    }

    useEffect(() => {
        fetchData()
    }, [])

    function formatDateToCustomFormat(inputDate: any) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const customFormat = `${year}-${month}-${day}`; return customFormat;
    }

    useEffect(() => {
        const startDate = new Date(selectedDate.startDate);
        startDate.setHours(0, 0, 0, 0)
        let endDate: Date | undefined = undefined;
        if (selectedDate.endDate !== '') {
            endDate = new Date(selectedDate.endDate);
            endDate.setHours(0, 0, 0, 0)
        }
        if (endDate) {
            if (startDate > endDate) {
                setSelectedDate((preav: any) => {
                    return { ...preav, endDate: '' }
                })
            }
        }


    }, [selectedDate.endDate, selectedDate.startDate])

    useEffect(() => {
        if (selectedDate && selectedDate.startDate !== '' && selectedDate.endDate !== '') {
            const startDate = new Date(selectedDate.startDate);
            startDate.setHours(0, 0, 0, 0)
            const endDate = new Date(selectedDate.endDate);
            endDate.setHours(0, 0, 0, 0)
            filterDataForSelectedDate(startDate, endDate)
        }
    }, [selectedDate])

    const handleDate = (e: any, field: any) => {
        const value = formatDateToCustomFormat(e.$d.toLocaleDateString('en-us')).toString()
        setSelectedDate((preav: any) => {
            return { ...preav, [field]: value }
        })

    }

    const formateDate = (data: any) => {
        const currentDate = data?.split('/', 3)
        const FormateDate = `${currentDate[2]}-${currentDate[1]}-${currentDate[0]}`
        const newFormateDate = new Date(FormateDate)
        return newFormateDate;
    }

    const filterDataForSelectedDate = (startDate?: any, endDate?: any, response?: WorkFromHomeProps[]) => {
        if (response !== undefined) {
            var workFromHome = response
            setSortedWorkFromHomeDate(response)
        } else {
            var workFromHome = workFromHomeData
        }
        const today = new Date()
        let startDateData: Date = new Date(startDate !== null ? startDate : today)
        let endDateData: Date = new Date(endDate !== null ? endDate : today)
        startDateData.setHours(0, 0, 0, 0)
        endDateData.setHours(0, 0, 0, 0)
        const timeDifference = endDateData.getTime() - startDateData.getTime();
        const numberOfDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;
        if (startDateData && endDateData) {
            const filteredData = workFromHome?.map((employee: any) => ({
                ...employee,
                wfhDetails: employee.wfhDetails.filter((details: any) => {
                    const wfhFromDate = formateDate(details.fromDate)
                    wfhFromDate.setHours(0, 0, 0, 0)
                    const wfhToDate = formateDate(details.toDate)
                    wfhToDate.setHours(0, 0, 0, 0)
                    if (numberOfDays >= 1) {
                        // return wfhFromDate >= startDateData && wfhToDate <= endDateData;
                        return (startDateData <= wfhToDate && endDateData >= wfhFromDate);
                    } else {

                    }
                })
            }))
            setSortedWorkFromHomeDate(filteredData)
        }
    }

    const handleDetails = (element: any) => {
        handleOpen()
        setViewData(element)
    }

    const handleEditEmployees = (params: boolean) => {
        if (params) {
            fetchData()
        }
    }

    return (
        <>
            <div className='main'>
                <div className='main-container'>
                    <div className='wfh-heading'>
                        <h2>Work From Home</h2>
                        <div className='wfh-date-range-container'>
                            {
                                inputField.map((item: any, index: number) => {
                                    return <div key={index} className='wfh-date-container'>
                                        <div className='wfh-dates-container'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker
                                                    label={item.label}
                                                    format='DD/MM/YYYY'
                                                    minDate={item.name === 'endDate' && selectedDate.startDate ? dayjs(selectedDate.startDate) : undefined}
                                                    onChange={(e) => handleDate(e, item.name)}
                                                    value={selectedDate[item.name]}
                                                    closeOnSelect={true}
                                                />
                                            </LocalizationProvider>
                                        </div >
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div className='work-from-home-sub-container'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th className='wfh-table-head1'>Name</th>
                                    <th className='wfh-table-head1'>Id</th>
                                    <th className='wfh-table-head'>
                                        <p className='width25'>From Date</p>
                                        <p className='width25'>To Date</p>
                                        <p className='width20'>Reason</p>
                                        <p className='width10'>Days</p>
                                        <p className='width20'>Status</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sortedWorkFromHomeDate.length > 0 ? <>
                                        {sortedWorkFromHomeDate.length > 0 && sortedWorkFromHomeDate.map((el: WorkFromHomeProps, ind: number) => {
                                            return <tr key={ind}>
                                                <td>{el.employeeName}</td>
                                                <td>{el.employeeId}</td>
                                                {
                                                    el.wfhDetails.map((ele: any, index: number) => (
                                                        <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '50px' }} key={index}>
                                                            <p className="width25">{ele.fromDate}</p>
                                                            <p className="width25">{ele.toDate}</p>
                                                            <p className="width20">
                                                                <ButtonWrapper title='View' onClick={() => handleDetails(ele)} />
                                                            </p>
                                                            <p className="width10">{ele.noOfDays}</p>
                                                            <p className="width20">
                                                                <ApproveRejectCell value={ele} handleEditEmployees={handleEditEmployees} actionStaus={true} />
                                                            </p>
                                                        </td>
                                                    ))
                                                }


                                            </tr>
                                        })
                                        }
                                    </> : null
                                }
                            </tbody>
                        </table>
                        <ModalWrapper open={openModal} close={handleClose} children={<ViewWorkFromHomeReasonDetails data={viewData} onClose={handleClose} />} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default WorkFromHome